import NewsLetter from "src/components/newsletter";
import Layout from "../../components/layout";
import Brands from "./components/brands";
import Companies from "./components/companies";
import Experience from "./components/experience";
import HeroBanner from "./components/heroBanner";

export default function Home() {
  return (
    <Layout>
      <HeroBanner></HeroBanner>
      <Experience></Experience>
      <Brands></Brands>
      <Companies></Companies>
      <NewsLetter></NewsLetter>
    </Layout>
  );
}
