import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, CaretSortIcon } from "@radix-ui/react-icons";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./form";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { CommandList } from "cmdk";
import { countriesData } from "src/data/countries";

const FormSchema = z.object({
  language: z.string({
    required_error: "Please select a language.",
  }),
});

export function ComboboxForm(props:any) {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });


  function handleSubmit(e:any) {
    props.callback(e);
  }


  return (
    <Form {...form}>
      <form className="space-y-6">
        <FormField
          control={form.control}
          name="language"
          render={({ field }: any) => (
            <FormItem className="flex flex-col">
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "w-full sm:w-[90%] justify-between bg-opacity-80 bg-[#515151] border-none h-16 hover:bg-[#515151] hover:text-white",
                        !field.value
                      )}>
                      {field.value
                        ? countriesData.find(
                            (country) => country.code === field.value
                          )?.name
                        : "SELECT COUNTRY"}
                      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="p-0 max-w-[250px]">
                  <Command>
                    <CommandInput
                      className="text-white"
                      placeholder="Search country..."
                    />
                    <CommandEmpty className="text-white py-4 text-center">
                      No country found.
                    </CommandEmpty>
                    <CommandGroup className="max-h-[350px] overflow-y-auto w-full">
                      <CommandList>
                        {countriesData.map((country, index) => (
                          <CommandItem
                            className="text-white"
                            value={country.name}
                            key={index}
                            onSelect={() => {
                              form.setValue("language", country.code);
                              handleSubmit(country.name)
                            }}>
                            <CheckIcon
                              className={cn(
                                "mr-2 h-4 w-4",
                                country.code === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {country.name}
                          </CommandItem>
                        ))}
                      </CommandList>
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
