import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
export default function CardCategory({
  id,
  name,
  image,
  miniDescription
}: {
  id: string,
  name: string;
  image: string;
  miniDescription: string
}) {
  return (
    <div className="bg-[#FAFAFA] rounded-2xl p-6 mb-6 flex flex-col justify-between">
      <div className="flex flex-nowrap justify-between">
        <div className="w-[40%] text-3xl font-bold text-[#003459]">{name}</div>
        <div className="w-[50%]">
          <img src={require(`../assets/categories/${image}`)} alt="" />
        </div>
      </div>
      <div className="grid grid-cols-[3fr_2fr] mt-6">
        <div className="text-[#003459]">{miniDescription}</div>
        <Link to={`/ourProducts/${id}`} className="flex items-center font-semibold justify-end gap-3 text-lg text-[#0065AD] group hover:underline hover:cursor-pointer">
          Explore
          <div>
            <ArrowRightIcon className="group-hover:underline"></ArrowRightIcon>
          </div>
          
        </Link>
      </div>
    </div>
  );
}
