import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Layout from "src/components/layout";
import NewsLetter from "src/components/newsletter";

export default function AboutUs() {
  return (
    <Layout>
      <div className="bgHeroBanner">
        <div className="grid grid-cols-[1fr_2fr] lg:flex lg:w-[100%] text-white py-10 px-[5%] mx-auto max-w-[1400px] gap-5">
          <div className="aboutUsImagesContainer lg:w-[30%] lg:max-w-[320px]">
            <img src={require("../../assets/pages/aboutUs1.png")} alt=""></img>
            <img src={require("../../assets/pages/aboutUs2.png")} alt=""></img>
            <img src={require("../../assets/pages/aboutUs3.png")} alt=""></img>
          </div>
          <div className="flex flex-col gap-5 lg:flex-row lg:w-[65%]">
            <h1 className="text-4xl font-bold">About MARDIIN</h1>
            <div>
              <p>
                Founded in 2009 our company is focused on the development of
                world leading brands in the Central American region. We take
                advantage of a globalized market and create long term
                relationships with our business partners and customers to whom
                we offer new products that suit their lifestyles
              </p>
              <br></br>
              <p>
                In the past years, our group has been able to gain market share
                for its brands and more importantly has gained recognition for
                the work done in such a short period of time.
              </p>
              <br></br>
              <p>
                We operate as Premier Distributions in El Salvador, Honduras,
                Guatemala, Nicaragua, Panamá, Costa Rica and Belize. This
                centralization allows us to expand and become a fast-growing
                brand value development company that strives to provide high
                quality products to the most demanding consumers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ParallaxProvider>
        <div className="bg-white text-[#002138] py-10">
          <div className="ml-[37%] w-[60%] lg:flex lg:gap-4">
            <Parallax
              className="hidden lg:block"
              speed={-10}
              translateY={[-30, 60]}>
              <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
            </Parallax>
            <h2 className="text-2xl font-bold mb-4 block lg:hidden">
              Our Mission
            </h2>
            <p className="lg:mt-16">
              Provide a select variety of high-end products to the most
              demanding consumers in Central America, by building healthy
              business relationship both with our partners and retailers who
              serve the end consumer. Satisfied consumers have proven to be our
              most valuable asset.
            </p>
          </div>
          <div className="py-12 px-2 max-w-[1200px] lg:mx-auto flex overflow-x-scroll [&>img]:rounded-3xl [&>img]:w-[70%] [&>img]:max-h-[140px] gap-4 md:overflow-hidden md:[&>img]:rounded-none md:[&>img]:w-[30%] md:gap-[4%]">
            <img src={require("../../assets/pages/aboutUs4.png")} alt=""></img>
            <img src={require("../../assets/pages/aboutUs5.png")} alt=""></img>
            <img src={require("../../assets/pages/aboutUs6.png")} alt=""></img>
          </div>
          <div className="ml-[37%] w-[60%] lg:flex lg:gap-4">
            <Parallax
              className="hidden lg:block"
              speed={-12}
              translateY={[-30, 60]}>
              <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
            </Parallax>
            <h2 className="text-2xl font-bold mb-4 block lg:hidden">
              Our Vision
            </h2>
            <p className="lg:mt-16">
              Become a fast-growing brand building organization that exceeds the
              expectations of our customers, by providing them with a range of
              high-quality products and personalized attention. We will strive
              to maintain a close relationship with our direct customers and
              partners in order to reach more potential markets.{" "}
            </p>{" "}
          </div>
        </div>
      </ParallaxProvider>
      <NewsLetter></NewsLetter>
    </Layout>
  );
}
