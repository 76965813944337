import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";
import OurProducts from "./pages/our-products/our-products";
import AboutUs from "./pages/about-us/aboutUs";
import ContactUs from "./pages/contact-us/contactUs";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    const smoothScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    smoothScrollToTop();
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="ourProducts" element={<OurProducts />}>
        <Route path=":category" element={<OurProducts />}>
          <Route path=":subcategory" element={<OurProducts />}></Route>
        </Route>
      </Route>
      <Route path="/aboutUs" element={<AboutUs />}></Route>
      <Route path="/contactUs" element={<ContactUs />}></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
