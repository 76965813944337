import { useState } from 'react';
import { Controller, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export default function ProductsHeader({
  products,
  category,
}: {
  products: Array<any>;
  category: string;
}) {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  return (
    <div className="flex flex-col md:flex-row relative gap-4 mt-[240px] md:mt-0">
      <div className="bg-[#57B9FF] w-full md:left-[16%] md:w-[84%] mt-5 rounded-2xl absolute bluebgProducts"></div>
      <div className="h-[360px] absolute -top-48 md:left-0 md:top-0 md:relative md:h-auto w-full md:w-[30%]">
        <Swiper
          modules={[Controller]}
          onSwiper={(e: any) => setFirstSwiper(e)}
          controller={{ control: secondSwiper }}>
          {products.map((info, index) => (
            <SwiperSlide key={index}>
              <div className="absolute w-[100%] md:top-0 h-full">
                <img
                  className="object-contain w-full h-full"
                  src={require(`../../../assets/products/${category}/${info.img}`)}
                  alt={info.name}></img>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mt-[200px] md:mt-0 px-[5%] md:px-0 md:w-[65%] md:py-10">
        <Swiper className='productSwiper'
          style={{
            "--swiper-navigation-size": "15px",
          }}
          modules={[Controller, EffectFade, Navigation, Pagination]}
          onSwiper={(e: any) => setSecondSwiper(e)}
          controller={{ control: firstSwiper }}
          effect="fade"
          navigation={true}
          pagination={{ clickable: true }}>
          {products.map((info, index) => (
            <SwiperSlide key={index}>
              <div className="text-white text-center md:text-left pt-12 md:pt-4 pb-16">
                <div className="flex flex-col gap-4 ">
                  <p className="font-bold text-center md:text-left text-3xl">
                    {info.name}
                  </p>
                  <p>{info.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
