export const CategoriesData = [
  {
    id: "spirits",
    category: "Spirits",
    descriptionMini:
      "A premium selection of renowned spirits, perfect for elevating any occasion with a touch of sophistication.",
    description:
      "Our 'Spirits' category features an exclusive selection of premium spirits, carefully curated to satisfy the most refined tastes. We offer a wide range of liquors, including whiskies, rums, vodkas, tequilas, and more, all from globally recognized brands. Each bottle is chosen with meticulous attention to detail, ensuring the highest quality and flavor. Whether for special celebrations or moments of relaxation, our spirits elevate every occasion, delivering a unique sensory experience that blends tradition and modernity in every sip.",
    img: "spirits.png",
    subcategories: [
      {
        name: "GIN",
        products: [
          {
            name: "MARTIN MILLERS GIN",
            description:
              "Martin Miller’s Original Gin. A modern classic dry gin based on the best traditions of English distillers but with our own twist to obtain the perfectly balanced gin. A gin that ‘tasted of gin’ in the words of Martin Miller. Nose Its alcohol level enhances a smooth flavour with lemon peels and pine notes; palate the slight liquorice sweetness enhance the citrus and spiced sensations; finish long and fresh feeling with lemon notes.",
            img: "martin_millers.webp",
          },
          {
            name: "BULLDOG GIN",
            description:
              "The iconic and multi award-winning BULLDOG Gin is a modern London Dry Gin with multiple layers of flavour and an unexpectedly smooth finish. Its 12 botanicals include the unique White Poppy from Turkey along with Dragon Eye and Lotus Leaf from China; adding a layer of distinction to its refreshing citrus notes.",
            img: "BULLDOG_GIN.webp",
          },
          {
            name: "PUERTO DE INDIAS CLASSIC",
            description:
              "It is a premium gin made in one of the dean distilleries of Andalusia, located in the Sevillian town of Carmona, through traditional production methods. Puerto de Indias Classic has a corpulent character and a transparent and crystalline appearance.",
            img: "PUERTO_DE_INDIAS_CLASSIC.webp",
          },
          {
            name: "PUERTO DE INDIAS STRAWBERRY",
            description:
              "Is a special gin made from a maceration of strawberries from Lepe, Huelva, and the recipe of Puerto de Indias Classic, prepared by the Sevillian distillery Anís Los Hermanos.",
            img: "PUERTO_DE_INDIAS_STRAWBERRY.webp",
          },
          {
            name: "BOLS GENEVER",
            description:
              "Bols Genever is the original Dutch spirit, created from lightly distilled Dutch grains and a complex botanical mix. It is made according to the original 1820 Lucas Bols recipe which stood at the basis of the cocktail revolution in 19th century America.",
            img: "BOLS_GENEVER.webp",
          },
        ],
      },
      {
        name: "LIQUOR",
        products: [
          {
            name: "HPNOTIQ",
            description:
              "HPNOTIQ is a blend of exotic and natural fruit juices, premium vodka and a touch of cognac and can be served mixed with premium spirits such as cognac, rum, vodka or champagne. Hpnotiq remains the second largest fruit-based liquor. Hpnotiq is the perfect storm of captivating flavor, striking blue color, and elegant packaging. The tasting notes of Hpnotiq are aromas of tropical fruits, citrus and mango. Light, acidic and very acidic entry into the mouth; The palate is refreshing and intensely juicy.",
            img: "HPNOTIQ.webp",
          },
          {
            name: "GRAN MALO TAMARINDO",
            description:
              "The only and delicious Gran Malo elixir. The delicious expression of tropical grapefruit taste tamarind hot with tequila. A different experience that will give you a nostalgic sensation. With notes of wet earth, caramel, fruit, with a light touch of spicy tamarind with a touch of tequila, the perfect combination for any occasion. This hot spirit can be enjoyed as a shot or mixed in your favorite cocktail.",
            img: "GRAN_MALO_TAMARINDO.webp",
          },
          {
            name: "GRAN MALO HORCHATA",
            description:
              "Gran Malo presents a new version, so you can degust its exquisite taste realized in base of Gran Malo Blanco, Gluten Free, Horchata, Toast canella and water of natural rice, a different taste and a unique for enjoy with family and friends.",
            img: "GRAN_MALO_HORCHATA.webp",
          },
          {
            name: "PAMA",
            description:
              "Pama is a liquor made from 100% natural pomegranate juice, premium vodka and a touch of tequila. Its intense ruby ​​color and bittersweet pomegranate tart flavor constitute the essence of this surprising liqueur, very versatile and which shows its characteristic notes both alone and in mixed drinks or cocktails. An innovation to incorporate into the cupboard of good drinks.",
            img: "PAMA.webp",
          },
          {
            name: "DOMAINE DE CANTON",
            description:
              "Domaine de Canton is the world's first ultra-premium ginger liqueur. It is inspired by the French tradition, in the Indochinese colonial era, of drinking sweet and fresh elixirs fortified with fine spirits and cognac. The best ingredients in the world are used for its preparation, without preservatives or stabilizers.",
            img: "DOMAINE_DE_CANTON.webp",
          },
          {
            name: "DU BOUCHETT BLUE CURACAO",
            description:
              "Du Bouchett Blue Curacao has a slightly bitter orange flavor, perfect in cocktails. Try the unique taste of Laraha oranges from the island of Curacao. Explore all the ways to enjoy this spirit type and share your favorite recipes with us! Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premise operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high quality product.",
            img: "DU_BOUCHETT_BLUE_CURACAO.webp",
          },
          {
            name: "DU BOUCHETT TRIPLE SEC",
            description:
              "Triple Sec offers a light and balanced orange flavor, perfect in cocktails. Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premises operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high-quality product. Du Bouchett is a versatile spirit which is easily enjoyed for a special occasion or can be simply sipped as an everyday cocktail.",
            img: "DU_BOUCHETT_TRIPLE_SEC.webp",
          },
          {
            name: "DU BOUCHETT CREME DE CACAO",
            description:
              "Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premise operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high quality product. Du Bouchett Creme De Cacao White has a sweet white chocolate flavor, scented with a hint of vanilla. This is a versatile spirit which is easily enjoyed for a special occasion or can be simply sipped as an everyday cocktail.",
            img: "DU_BOUCHETT_CREME_DE_CACAO.webp",
          },
          {
            name: "LICOR NIXTA",
            description:
              "Nixta Licor de Elote is a Mexican corn liqueur made from a base of tender, ancestral cacahuazintle maize, grown in the high valleys and foothills of the Nevado de Toluca volcano. A straw-like coloured liquid reveals aromas of cornbread and toasted corn. To taste, there's a full dose sweet corn perfectly balanced with toasted notes adding vanilla and caramel like backdrop. A great base for a twist on the Spritz.",
            img: "LICOR_NIXTA.webp",
          },
          {
            name: "LICOR LUXARDO MARASCHINO",
            description:
              "Maraschino Luxardo is a delicious liqueur made from cherries that are extracted from the same plantation of the Luxardo house; became an essential ingredient in cocktails internationally, along with other products that the Luxardo family began to produce shortly after the foundation, such as the “Sangue Morlacco” Cherry Liqueur, the Original Maraschino Cherries, the Limoncello and many others.",
            img: "LICOR_LUXARDO_MARASCHINO.webp",
          },
          {
            name: "NUVO LICOR",
            description:
              "Nuvo Licor is an elegant liqueur known for its delicate balance of fruit and floral notes. With hints of fresh citrus, berries, and a touch of sweetness, it offers a sophisticated flavor profile. Perfect for sipping or as a unique addition to cocktails.",
            img: "NUVO_LICOR_ESPUMOSO.webp",
          },
          {
            name: "STAMBECCO",
            description:
              "Stambecco is a premium spirit crafted with a blend of high-quality ingredients and traditional techniques. It features rich notes of smooth vanilla, toasted nuts, and a hint of spice, offering a refined and balanced flavor. Ideal for sipping or enhancing your favorite cocktails.",
            img: "STAMBECCO.webp",
          },
          {
            name: "CHINOLA",
            description:
              "Chinola is a vibrant liqueur made from passion fruit. It delivers a refreshing burst of tropical fruit flavor with a hint of sweetness. Ideal for enjoying on its own or as a flavorful addition to cocktails.",
            img: "CHINOLA.webp",
          },
          {
            name: "AMARO DE ANGOSTURA",
            description:
              "Amaro de Angostura is a distinctive herbal liqueur crafted with a blend of aromatic herbs and spices. It offers complex notes of bitter citrus, warm spices, and subtle sweetness, making it perfect for sipping neat or enhancing cocktails.",
            img: "AMARO_DE_ANGOSTURA.webp",
          },
        ],
      },
      {
        name: "TEQUILA",
        products: [
          {
            name: "TEQUILA OCHO",
            description:
              "Unaged and honors the noble agaves of Arandas and the Camarena “Ranchos” through intense, lively and clean flavors Made with 100% Blue Agave. Tequila Ocho is a tequila that is made traditionally through a slow cooking process, Ocho accentuates the agave flavor and its flavor develops over time.",
            img: "TEQUILA_OCHO.webp",
          },
          {
            name: "TEQUILA MI CAMPO REPOSADO",
            description:
              "Mi Campo Reposado begins as 100% Blue Weber Agave, matured for seven years. It´s gently squeeze the cooked pineapple, illuminating the agave plant’s natural honey characteristic and quelling any trace of bitterness.",
            img: "TEQUILA_MI_CAMPO_REPOSADO.webp",
          },
          {
            name: "TEQUILA MI CAMPO BLANCO",
            description:
              "Mi Campo Blanco begins as 100% Blue Weber Agave, matured for seven years. It´s gently squeeze the cooked pineapple, illuminating the agave plant’s natural honey characteristic and quelling any trace of bitterness.",
            img: "TEQUILA_MI_CAMPO_BLANCO.webp",
          },
          {
            name: "MILAGRO SILVER",
            description:
              "Tequila Milagro silver is a 100% blue agave tequila that is renowned for its crisp, fresh, agave taste and world-class smoothness.",
            img: "TEQUILA_MILAGRO_SILVER.webp",
          },
          {
            name: "MILAGRO REPOSADO",
            description:
              "Tequila Milagro Reposado is a 100% blue agave tequila which is rested in American oak barrels for up to 4 months for a robust flavor with front-and-center agave taste.",
            img: "TEQUILA_MILAGRO_REPOSADO.webp",
          },
          {
            name: "ESPOLÓN BLANCO",
            description:
              "Unaged, Blanco is the purest expression of Espolòn tequila. Created in the famed hills of Los Altos, is double distilled using column and pot stills to give it a perfectly smooth and balanced taste profile. Great on the rocks, this is the one you’ll love shaking into all the best tequila cocktails.",
            img: "ESPOLON_BLANCO.webp",
          },
          {
            name: "ESPOLÓN REPOSADO",
            description:
              "Tequila Reposado starts off life as Blanco. It’s then rested in lightly charred, new American oak barrels to create a more complex and well-rounded character uniquely Espolòn. This one begs to be shared in cocktails that deserve even greater tequila character.",
            img: "ESPOLON_REPOSADO.webp",
          },
        ],
      },
      {
        name: "VODKA",
        products: [
          {
            name: "BOLS VODKA",
            description:
              "Bols Vodka has a superbly clean and pure palate, created through a process of charcoal filtering. The extraordinary level of purity makes Bols Vodka perfect for mixing in a huge variety of cocktails and mixers. A widely known and celebrated expression from the Bols range!",
            img: "BOLS_VODKA.webp",
          },
          {
            name: "Tito’s Vodka",
            description:
              "Distilled from corn, this handmade vodka offers forward and yet delicate aromas and flavours of roasted grain, black pepper and citrus, with hints of fruit and smoke. It's lightly creamy and smooth on the palate with a subtle sweetness on the warm and rounded finish.",
            img: "TITOS_VODKA.webp",
          },
        ],
      },
      {
        name: "WHISKEY",
        products: [
          {
            name: "EVAN WILLIAMS FIRE",
            description:
              "This seriously good cinnamon infused Bourbon takes the term fire in your belly to a whole new level. It’s got the hot cinnamon taste you love, with just the right amount of smooth Kentucky Bourbon blended in. 70 Proof with a light copper color, Fire brings the heat and keeps it going all night long. All Heat. No Burn. It’s finally ok to play with fire.",
            img: "EVAN_WILLIAMS_FIRE.webp",
          },
          {
            name: "EVAN WILLIAMS HONEY",
            description:
              "End a long day with Evan Williams Honey, a bourbon whiskey thats all flavor, no sting. Evan Williams Honey features a nose of citrus and hints of honey with a long, slightly spicy finish. Evan Williams Honey combines the smoothness of Straight Kentucky Bourbon with the sweetness of real, natural honey. Enjoy Evan Williams Honey neat in a whiskey glass, on the rocks, in a signature craft cocktail or mixed with iced tea. Evan Williams Honey is perfect for sharing with loved ones.",
            img: "EVAN_WILLIAMS_HONEY.webp",
          },
          {
            name: "GLENFIDDICH 12",
            description:
              "Tullamore D.E.W. is the original triple blend Irish whiskey known the world over for its smooth and gentle complexity. Three spirits come together to give a balance of flavour like no other. Grain whiskey gives sweet flavours. Malt whiskey gives fruit flavours. And pot still whiskey gives spice flavours.",
            img: "GLENFIDDICH_12.webp",
          },
          {
            name: "GLENFIDDICH 15",
            description:
              "Glenfiddich 15 Year Old is a single malt Scotch whiskey with intriguing aromas and an explosion of flavors. Glenfiddich's 15-year maturation process takes place in three different types of oak barrels: barrels from the maturation of Sherry wines, new oak barrels and American oak barrels.",
            img: "GLENFIDDICH_15.webp",
          },
          {
            name: "GRANTS TRIPE WOOD",
            description:
              "Grant's Triple Wood is a whiskey aged in three different types of barrels that offers a delicious and silky flavor. Bright amber color, on the nose it gives notes of ripe pears and summer fruits; In the mouth there is a balance between vanilla and malt, with a long and sweet finish, with a little touch of smoke.",
            img: "GRANTS_TRIPLE_WOOD.webp",
          },
          {
            name: "THE GLENROTHES",
            description:
              "The Glenrothes Manse Reserve is a whisky of unsurpassed quality. Crafted by Glenrothes Distillery, it is an unmistakable expression of the own style they have maintained over the years. This drink has been aged in ex-Bourbon barrels mainly, with the incorporation of a smaller portion from ex-Sherry barrels.",
            img: "THE_GLENROTHES.webp",
          },
          {
            name: "ABASOLO",
            description:
              "Is the first whisky made using exclusively 100% Peanut Creole corn, grown for hundreds of years by local farmers, this offers something unique that no other whisky has achieved in history. It is aged in an open cellar at Destilería y Bodega Abasolo, the first and only distillery dedicated solely to the production of whiskey in Mexico.",
            img: "ABASOLO.webp",
          },
          {
            name: "CUTTY SARK",
            description:
              "The first and most famous of the Cutty Sark family, this blended scotch has been a worldwide whisky icon for the last 90 years. Cutty Sark is an easy-to-drink blended scotch whisky, which is light in colour and flavour It was created for mixing with mates without overwhelming anyone’s taste buds.",
            img: "CUTTY_SARK.webp",
          },
          {
            name: "DRAMBUIE",
            description:
              "Made from honey and a secret herb and spice recipe, the legendary Scotch whiskey liqueur combines the essence created for Prince Charles of Scotland with fine aged Scottish malt. A blend of aged Scotch whisky, spices, herbs & heather honey.",
            img: "DRAMBUIE.webp",
          },
          {
            name: "MONKEY SHOULDER",
            description:
              "Was originally created to make perfect whisky cocktails and has been getting in the mix ever since. It's a blend of malt whiskies from the Speyside (Highland) region of Scotland.",
            img: "MONKEY_SHOULDER.webp",
          },
          {
            name: "TULLAMORE D.E.W",
            description:
              "Tullamore D.E.W. is the original triple blend Irish whiskey known the world over for its smooth and gentle complexity. Three spirits come together to give a balance of flavour like no other. Grain whiskey gives sweet flavours. Malt whiskey gives fruit flavours. And pot still whiskey gives spice flavours.",
            img: "TULLAMORE.webp",
          },
        ],
      },
      {
        name: "MEZCAL",
        products: [
          {
            name: "MONTELOBOS ENSAMBLE",
            description:
              "This Montelobos expression offers an explosion of contrasting flavors such as pink pepper, chamomile and candied apple. It has a body and an acidity in the mouth that highlights smoke and fruit.",
            img: "MONTELOBOS_ENSAMBLE.webp",
          },
          {
            name: "MONTELOBOS ESPADÍN",
            description:
              "Is an unaged joven mezcal, crafted from meticulously, cultivated organic agave Espadin, which is roasted underground and small batch distilled to achieve a perfect balance of sweetness and subtle smoke.",
            img: "MONTELOBOS_ESPADÍN.webp",
          },
          {
            name: "MONTELOBOS TOBALÁ",
            description:
              "Is an unaged joven mezcal, crafted from meticulously, cultivated organic agave, which is roasted underground and small batch distilled to achieve a perfect balance of sweetness and subtle smoke.",
            img: "MONTELOBOS_TOBALA.webp",
          },
          {
            name: "OJO DE TIGRE JOVEN",
            description:
              "Ojo de Tigre Joven is produced with maguey Espadin (agave Angustifolia) from Oaxaca and maguey Tobala (agave Potatorum) from Puebla. The agaves are cooked in an underground pit over, milled with a tahona, and double distilled in a copper alembique still. It’s Crafted with two sustainably cultivated agaves, Espadin from Oaxaca and Tobala from Puebla, Ojo de Tigre Mezcal delivers a balanced flavor that welcomes first time mezcal drinkers to the world of mezcal.",
            img: "OJO_DE_TIGRE_JOVEN.webp",
          },
          {
            name: "OJO DE TIGRE REPOSADO",
            description:
              "The Ojo de Tigre is produced in Mexico according to 500 years old tradition. Espadín and Tobalá agaves are used for this special mezcal. After the plant is cooked, it is fermented and distilled in Copper Stills. Tasting notes: Color: gold. Nose: Agave notes, sweetish, soft. Taste: Slightly smoky, well balanced. Finish: Long lasting.",
            img: "OJO_DE_TIGRE_REPOSADO.webp",
          },
          {
            name: "MEZCAL EL RECUERDO JOVEN",
            description:
              "Without agave worm, for those looking for more traditional smoky notes, without extra complexity. Crystalline and shiny in color, with silver veins; Notes of fresh herbs, delicately smoked by the cooked agave. And balanced sweet and sour flavor, fresh citrus, herbs and delicate smoky effervescence.",
            img: "MEZCAL_EL_RECUERDO_JOVEN.webp",
          },
          {
            name: "MEZCAL EL RECUERDO CON ABOCADO DE GUSANO",
            description:
              "Abocado con worm is for those who prefer the silky texture that the agave worm adds to Mezcal, bright yellow in color with golden sparkles; with soft herbal notes along with the exotic aroma of agave worm. With bittersweet notes and a subtle smoky effervescence.",
            img: "MEZCAL_EL_RECUERDO_CON_ABOCADO_DE_GUSANO.webp",
          },
        ],
      },
      {
        name: "RUM",
        products: [
          {
            name: "CIHUATÁN SUERTE",
            description:
              "Cihuatan Suerte is a premium Salvadoran rum that celebrates luck and tradition. With notes of caramel, tropical fruit, and spice, it offers a refined taste for special moments. Enjoy neat, on the rocks, or in your favorite cocktails.",
            img: "CIHUATÁN_SUERTE.webp",
          },
          {
            name: "RON CIHUATÁN ALUX",
            description:
              "Cihuatan Alux is a premium Salvadoran rum inspired by ancient myths and mystical guardians. This limited edition offers a rich blend of caramel, dried fruit, and warm spice, crafted for a smooth and unique experience. Perfect for sipping or elevating cocktails.",
            img: "RON_CIHUATÁN_ALUX.webp",
          },
          {
            name: "RON CIHUATÁN CINABRIO",
            description:
              "Cihuatan Cinabrio is a premium Salvadoran rum inspired by the Mayan god of the sun. With vibrant notes of ripe red fruit, vanilla, and spices, it delivers a smooth and full-bodied taste. Perfect for sipping neat or in sophisticated cocktails.",
            img: "RON_CIHUATÁN_CINABRIO.webp",
          },
          {
            name: "RON CIHUATÁN INDIGO",
            description:
              "Cihuatan Indigo is a premium Salvadoran rum inspired by the Mayan god of rain. It features a smooth blend of vanilla, dark chocolate, and spice, capturing the essence of tranquility. Ideal for sipping or mixing in classic cocktails.",
            img: "RON_CIHUATÁN_INDIGO.webp",
          },
          {
            name: "RON CIHUATÁN SAHUMERIO",
            description:
              "Cihuatan Sahumerio is a limited-edition Salvadoran rum crafted to celebrate love and tradition. Infused with notes of roasted cacao, dried fruit, and delicate spice, it delivers a warm and rich flavor. Perfect for special moments or gifting.",
            img: "RON_CIHUATÁN_SAHUMERIO.webp",
          },
          {
            name: "RON CIHUATÁN JADE",
            description:
              "Cihuatan Jade is a premium Salvadoran rum inspired by the legendary stone of life and prosperity. With fresh notes of citrus, sugarcane, and subtle spice, it offers a light and refreshing taste. Perfect for sipping or enhancing vibrant cocktails.",
            img: "RON_CIHUATÁN_JADE.webp",
          },
        ],
      },
      {
        name: "DIGESTIVES",
        products: [
          {
            name: "ANCHO REYES CHILE VERDE POBLANO",
            description:
              "Ancho Reyes Verde has a bright and herbal taste with notes of golden pineapple and a savory acidity finishing with the crisp, fresh heat of the poblano chile.",
            img: "ANCHO_REYES_CHILE_VERDE_POBLANO.webp",
          },
          {
            name: "ANCHO REYES CLASSIC",
            description:
              "Ancho Reyes has a rich and complex warmth, followed by sweet spices and subtle acidity, and finishes with the pleasant, smoky heat of the ancho chile. Hints of spices, tamarind, plum, cacao, apple and almonds then subtle notes of fine herbs.",
            img: "ANCHO_REYES_CLASSIC.webp",
          },
          {
            name: "GRAND MARNIER",
            description:
              "Grand Marnier is a French brand of liqueurs. The brand’s best-known product is Grand Marnier Cordon Rouge, an orange-flavored liqueur created in 1880 by Alexandre Marnier-Lapostolle. It is made from a blend of Cognac brandy, distilled essence of bitter orange, and sugar.",
            img: "GRANDMARNIER.webp",
          },
          {
            name: "JAGERMEISTER",
            description:
              "Jägermeister is the most successful liqueur brand world-wide. 56 different herbs, blossoms, roots and fruits go to make up the inimitable and complex taste experience. You will discover well-balanced flavours of citrus, ginger, star anise and a gentle, pleasing herbal bitterness.",
            img: "JAGERMEISTER.webp",
          },
          {
            name: "JAGERMEISTER MANIFEST",
            description:
              "Jägermeister Manifest is a premium herbal liqueur and line extension in the Jägermeister family. Jägermeister Manifest is distinct in taste, character, and processes but is closely tied to original Jägermeister as it shares the same base.",
            img: "JAGERMEISTER_MANIFEST.webp",
          },
          {
            name: "LICOR 43",
            description:
              "With a vibrant gold color, soft and sweet flavor and with an inner light that gives it a unique personality and character, Licor 43 owes its aroma of citrus and orange blossom , which gives it a moving and suggestive nuance. This nuance is enhanced by the subtlety of a delicate touch of vanilla.",
            img: "LICOR_43.webp",
          },
        ],
      },
      {
        name: "BITTER",
        products: [
          {
            name: "BITTER LUXARDO",
            description:
              "Luxardo Bitter is obtained from a separate infusion of bitter herbs, aromatic plants and citrus fruits in water and alcohol. Such infusions are then blended together according a Luxardo Family original recipe before being filtered and bottled. Bright red in color, herbal and citrusy aromas meet distinctive gentian and wormwood flavors, which distinguish Luxardo Bitter. Ideal for many classic cocktails such as Americano or Negroni, it can also be enjoyed neat or over ice as an aperitivo. Try it as well hard shaken and double strained in a coupette, you’ll have the perfect “Shakerato”.",
            img: "LUXARDO.webp",
          },
          {
            name: "BITTER LUXARDO BIANCO",
            description:
              "Bitter Bianco shares the same infusions of bitter herbs, aromatic plants and citrus fruits with the “red” Bitter. However, before the blending process, most of these infusions are distilled, which makes the product colorless and slightly modifies the flavor profile. At the end of the process, an infusion of Roman wormwood is added to enhance the bitter flavor, giving the product an ivory color. Bitter Bianco is perfect for making a “twist” of classic cocktails such as the Negroni that becomes a Negroni Bianco, a Paloma into a Paloma Bianco, etc. But it can also be enjoyed alone or with ice as a classic aperitif.",
            img: "BITTER_LUXARDO_BIANCO.webp",
          },
          {
            name: "SUZE BITTER",
            description:
              "The iconic French bitter with a distinctive bittersweet taste, a bright yellow color and a unique personality for a refreshing and flavorful drinking experience. Color: bright yellow color; nose: intense and harmonious, with fruity aromas and vegetal notes; palate: bitterness and sweetness followed by a fresh taste of gentian roots, a fruity taste of orange and candied fruits. Suze is perfect to light a cocktail up for a flavorful drinking experience.",
            img: "SUZE_BITTER.webp",
          },
        ],
      },
    ],
  },
  {
    id: "beers",
    category: "Beers",
    descriptionMini:
      "A curated collection of top-quality beers, crafted to satisfy diverse tastes and occasions.",
    description:
      "Our 'Beers' category offers a carefully curated selection of top-quality brews, catering to a wide range of preferences and occasions. From crisp lagers to rich ales, and from well-known international brands to artisanal craft options, our assortment ensures something for every beer enthusiast. Each beer is chosen for its distinctive flavor profile and quality, making it perfect for everything from casual gatherings to special celebrations. Whether you're looking for a refreshing drink after a long day or a unique brew to share with friends, our collection is designed to enhance every moment.",
    img: "beers.png",
    subcategories: [
      {
        name: "LAGER",
        products: [
          {
            name: "ASAHI BEER",
            description:
              "It is a lager-type blonde beer that has captivated beverage consumers. It has a refreshing flavor with a light citrus touch, to contrast the concentration of malt. Light golden in color, medium body and a slight intensity in its malt aroma. It has a notable and short flavor with a touch of rice, which gives it a touch of dry palate. Excellent to accompany Japanese food.",
            img: "ASAHI_BEER.webp",
          },
          {
            name: "GROLSCH",
            description:
              "Grolsch is a classic Dutch lager with a crisp, clean taste and a slightly malty sweetness. Its balanced flavor profile and refreshing finish make it a versatile choice for pairing with a variety of dishes or enjoying on its own.",
            img: "GROLSCH.webp",
          },
          {
            name: "PERONI",
            description:
              "Peroni is an Italian lager with a light, crisp flavor and a hint of bitterness. Its clean, refreshing profile and subtle malt sweetness make it an excellent choice for pairing with Italian cuisine or enjoying as a refreshing drink on its own.",
            img: "PERONI.webp",
          },
          {
            name: "MAHOU 5 STARS",
            description:
              "Cerveza Mahou is a premium Spanish lager with a smooth, balanced flavor and a hint of malt sweetness. Its refreshing taste and slightly bitter finish make it ideal for pairing with a variety of dishes or enjoying on its own.",
            img: "Mahou.webp",
          },
          {
            name: "CERVECERIA 14",
            description:
              "Cervecería 14 offers a range of craft beers with distinctive flavors and styles. Each brew is crafted with quality ingredients, delivering unique profiles from hoppy IPAs to rich stouts. Ideal for exploring diverse tastes and enjoying artisanal brews.",
            img: "Cerveceria14.webp",
          },
        ],
      },
      {
        name: "ALE",
        products: [
          {
            name: "DELIRIUM NOCTURNUM",
            description:
              "Delirium Nocturnum is a dark Belgian ale with complex flavors of roasted malt, dark chocolate, and hints of dark fruit. Its rich, smooth profile and warming finish make it a perfect choice for savoring on cool evenings or pairing with rich, savory dishes.",
            img: "Delirium_Nocturnum.webp",
          },
          {
            name: "DELIRIUM RED",
            description:
              "Delirium Red is a fruity Belgian ale with vibrant flavors of cherry, raspberry, and a touch of vanilla. Its sweet and tangy profile, combined with a light carbonation, makes it a refreshing and enjoyable choice for those who appreciate a unique, fruit-forward beer.",
            img: "Delirium_Red.webp",
          },
          {
            name: "DELIRIUM TREMENS",
            description:
              "Delirium Tremens is a classic Belgian strong ale with rich flavors of malts, spices, and a hint of fruit. Its smooth, balanced profile and moderate bitterness are complemented by a warming finish, making it a distinctive and enjoyable choice for ale enthusiasts.",
            img: "Delirium_Tremens.webp",
          },
        ],
      },
      {
        name: "NON ALCOHOLIC",
        products: [
          {
            name: "ASAHI SUPER DRY 0.0",
            description:
              "Asahi Super Dry 0.0 is a refreshing non-alcoholic beer with a crisp, clean taste. It features the signature dry finish and light body of Asahi Super Dry, offering a satisfying alternative for those seeking a great beer flavor without the alcohol.",
            img: "ASAHI_SUPER_DRY_0.0.webp",
          },
        ],
      },
    ],
  },
  

  {
    id: "w&j",
    category: "WATERS & JUICES",
    descriptionMini:
      "A curated selection of refreshing waters and natural juices, perfect for hydration and enjoyment.",
    description:
      "Our 'Water & Juices' category offers a carefully curated range of refreshing beverages, including pure, crisp waters and natural, flavorful juices. Each product is selected for its quality and taste, providing the perfect balance of hydration and enjoyment. Whether you're looking for a revitalizing drink to start your day or a refreshing option to pair with meals, our collection caters to all preferences. From sparkling waters to freshly pressed juices, we ensure that each sip delivers a burst of freshness, ideal for any occasion, from daily hydration to special gatherings.",
    img: "waters.png",
    subcategories: [
      {
        name: "JUICES",
        products: [
          {
            name: "OCEANSPRAY RUBYRED",
            description:
              "It is made from 100% of the sweet and invigorating pink grapefruit flavor. It gives you your daily dose of vitamin C in every glass, and helps you strengthen your immune system. Plus, it's naturally fat-free, cholesterol-free, and low in sodium. in high fructose corn syrup; no artificial flavors or preservatives; no added sugars; good source of vitamin C.",
            img: "OCEANSPRAY_RUBYRED.webp",
          },
          {
            name: "OCEANSPRAY CRAN UVA",
            description:
              "It combines the juicy flavor of grapes with the freshness and purity of cranberries to create a juice drink that is good for you, fat-free and low in sodium.",
            img: "OCEANSPRAY_CRAN_UVA.webp",
          },
          {
            name: "OCEAN SPRAY CRAN RASPBERRY",
            description:
              "Ocean Spray Cran-Raspberry Juice Drink combines the delicious flavor of raspberries and the fresh, clean taste of blueberries to make a healthy, fat-free, low-sodium, daily dose juice drink of vitamin C.",
            img: "OCEAN_SPRAY_CRAN_RASPBERRY.webp",
          },
          {
            name: "OCEAN SPRAY WHITE CRANBERRY AND PEACH",
            description:
              "Ocean Spray White Cranberry & Peach Juice is a refreshing and delicious drink that combines the sweet flavor of peaches with the tartness of white cranberries. It is a great source of hydration and provides a boost of energy. Plus, it contains no artificial flavors or preservatives, making it a healthier option for your daily drink.",
            img: "OCEAN_SPRAY_WHITE_CRANBERRY_AND_PEACH.webp",
          },
          {
            name: "OCEAN SPRAY PINK CRANBERRY JUICE",
            description:
              "Ocean Spray introduces its light and refreshing Pink Cranberry Cocktail Juice drink, made with pink cranberries picked by the cooperative's more than 700 farmers. Pink Cranberry Juice Cocktail, with a hint of bold and tart Cranberry Juice Cocktail, is only 100 calories per serving, contains 100% vitamin C per serving, and has no artificial flavors or preservatives.",
            img: "OCEAN_SPRAY_PINK_CRANBERRY_JUICE.webp",
          },
          {
            name: "OCEAN SPRAY CRAN LEMONADE",
            description:
              "Made with real fruit juice. Cranberry lemonade from concentrate, no artificial flavors or preservatives. Pasteurized. The balance of crisp, sun-ripened cranberries with fresh-tasting lemonade is a great summer thirst-quencher that's perfect any time of year. Serve over ice, blend into a smoothie, or freeze as a refreshing fruit pop! Contains 7% juice.",
            img: "OCEAN_SPRAY_CRAN_LEMONADE.webp",
          },
          {
            name: "OCEAN SPRAY CRAN CRANBERRY",
            description:
              "Enjoy the crisp, clean taste of real cranberries straight from the bog. It’s a delicious way to get your daily dose of vitamin C and unique cranberry health benefits. It’s good for you and your immune system.",
            img: "OCEAN_SPRAY_CRAN_CRANBERRY.webp",
          },
          {
            name: "OCEAN SPRAY CRAN GRAPE",
            description:
              "The juicy taste of grapes and crisp, clean taste of cranberries to make a good-for-you juice drink that's fat-free, low in sodium, and a daily dose of vitamin C.",
            img: "OCEAN_SPRAY_CRAN_GRAPE.webp",
          },
          {
            name: "OCEAN SPRAY CRAN GRAPE LIGHT",
            description:
              "Experience the delicious blend of cranberry and grape with Ocean Spray Cran-Grape Light. This light, refreshing beverage offers the same great taste with fewer calories and no added sugars. Perfect for a crisp, revitalizing drink anytime!",
            img: "OCEAN_SPRAY_CRAN_GRAPE_LIGHT.webp",
          },
        ],
      },
      {
        name: "WATERS",
        products: [
          {
            name: "SAN PELLEGRINO SPRAKLING DRINKS FLAVORS",
            description:
              "From traditional sparkling fruit drinks to low-calorie options, tasteful mixers and organic fruit tea, discover a world of fabulous flavours. These classic favourites are created with 100% Mediterranean fruits, carefully selected and crafted for your enjoyment.",
            img: "SANPELLEGRINO_ITALIAN_SPARKLING_DRINKS.gif",
          },
          {
            name: "SAN PELLEGRINO SPARKLING MINERAL WATER",
            description:
              "Discover a tasty new way to enhance your experience with a touch of flavor. The same soft bubbles of S.Pellegrino mixed with a vibrant blend of Mediterranean fruit flavors.Natural flavors. Zero calories. No artificial ingredients. Pair it with medium-flavored dishes, such as sautéed vegetables, white meat, or freshly made pasta. ",
            img: "SAN_PELLEGRINO_SPARKLING_MINERAL_WATER.webp",
          },
          {
            name: "ACQUA PANNA",
            description:
              "The unique flavor profile and mineral composition of Acqua Panna comes from its 14-year journey through the aquifer beneath the sundrenched hills of Tuscany, obtaining the unmistakable tasting water. There is a reason why the greatest chefs and sommeliers choose Acqua Panna. ",
            img: "ACQUA_PANNA.webp",
          },
          {
            name: "POLAR WATER",
            description:
              "Polar was born in the city of Worcester, Massachusetts. One of their main products is the Traditional Tonic Water & More One of the classic staples of any bar. Crafted with quinine naturally sourced from the bark of the cinchona tree the bitterness enhances quality vodka and botanical gins.",
            img: "POLAR_WATER.webp",
          },
          {
            name: "POLAR SELTZERS",
            description:
              "Enjoy the pure, sparkling taste of Polar Seltzers. With natural fruit flavors and zero calories or artificial additives, it's the perfect, refreshing choice for any moment. Stay revitalized with Polar Seltzers!",
            img: "POLAR_SELTZERS.gif",
          },
          {
            name: "SAN PELLEGRINO ESSENZA",
            description:
              "Discover a tasty new way to enhance your experience with a touch of flavor. The same soft bubbles of S.Pellegrino mixed with a vibrant blend of Mediterranean fruit flavors.Natural flavors. Zero calories. No artificial ingredients. Pair it with medium-flavored dishes, such as sautéed vegetables, white meat, or freshly made pasta.",
            img: "SAN_PELLEGRINO_ESSENZA.gif",
          },
          {
            name: "MAISON PERRIE",
            description:
              "Bubbles combine with delicious natural flavors in Perrier mineral waters. With just a touch of flavor, you can quench your thirst or cleanse your palate, with zero calories and zero sweeteners. Born in the south of France, Perrier carbonated mineral water has delighted people around the world for more than 150 years. It is a delicious alternative to soft drinks and a sophisticated option to satisfy your thirst. ",
            img: "MAISON_PERRIE.gif",
          },
          {
            name: "PERRIER",
            description:
              "Elegant, sparkling and refreshing. Perrier Carbonated Mineral Water has delighted generations of beverage seekers for over 150 years, with its unique blend of distinctive bubbles and balanced mineral content. ",
            img: "PERRIER.webp",
          },
          {
            name: "MIXERS SAN PELLEGRINO TONICA",
            description:
              "Sanpellegrino Tonica Oakwood is a dry and intense tonic water with specially selected oak extract. The oak extract gives it a totally original flavor, which you will never find in another tonic, with pleasant first-hand bitter notes. Sanpellegrino Tonica Citrus is a scented tonic with hints of orange peel and lemon. Sanpellegrino Ginger Beer is an elegant ginger beer with delicate hints of spice.",
            img: "MIXERS_SAN_PELLEGRINO_TONICA.gif",
          },
          {
            name: "PERRIER FLAVORS",
            description:
              "Bubbles combine with delicious natural flavors in Perrier mineral waters. With just a touch of flavor, you can quench your thirst or cleanse your palate, with zero calories and zero sweeteners. Born in the south of France, Perrier carbonated mineral water has delighted people around the world for more than 150 years. It is a delicious alternative to soft drinks and a sophisticated option to satisfy your thirst.",
            img: "SANPELLEGRINO_ITALIAN_SPARKLING_DRINKS.webp",
          },
        ],
      },
    ],
  },

  {
    id: "wines",
    category: "Wines",
    descriptionMini:
      "A premium selection of wines, offering a diverse range of flavors for every palate and occasion.",
    description:
      "Our 'Wines' category features a premium selection of wines, carefully chosen to satisfy a wide range of palates and occasions. From robust reds to crisp whites and delicate rosés, we offer an extensive variety of wines from renowned vineyards around the world. Each bottle is selected for its exceptional quality and distinctive character, making it ideal for everything from intimate dinners to grand celebrations. Whether you're a connoisseur or simply enjoy a good glass of wine, our collection is designed to provide a perfect pairing for any moment, enhancing the experience with rich, layered flavors and aromas.",
    img: "wines.png",
    subcategories: [
      {
        name: "APERITIF",
        products: [
          {
            name: "CRUZ DE ALBA FINCA LOS HOYALES",
            description:
              "Signature wine, complex and elegant, with a modern cut and delicate structure. 100% Tempranillo variety. Of a marked bright red color with violet notes and a middle layer, it shows a fine and elegant nose of black fruit, followed by spicy notes and memories of the mountains that blend with an elegant and subtle wood. With a tasty palate, it shows a noble and structured character, with sensations of black fruit and roasted notes, finishing with a harmonious and ripe tannin, leaving us with a seductive wine sensation.",
            img: "CRUZ_DE_ALBA_FINCA_LOS_HOYALES.webp",
          },
        ],
      },
      {
        name: "SANGRIA",
        products: [
          {
            name: "LOLEA 1",
            description:
              "A delicious frizzante sangria made with red wine from Tempranillo and Cabernet Sauvignon grapes, Mediterranean citrus fruits and a touch of cinnamon. A Cherry Red color; pleasant aromas, with notes of cherry, orange peel, lemon candy, cinnamon and sour strawberry and low alcohol content (7% VOL ALC)",
            img: "LOLEA_1.webp",
          },
          {
            name: "LOLEA 2",
            description:
              "A different sangria, with a frizzante touch, made with white wine from Macabeo and Airén grapes, Mediterranean citrus fruits and a touch of vanilla. Clear, clean, with slight effervescence of fine and constant bubbles; with notes of vanilla, jasmine, apricot; a balance between acidity and sweetness and low alcohol content (7% VOL ALC)",
            img: "LOLEA_2.webp",
          },
        ],
      },
      {
        name: "RED WINE",
        products: [
          {
            name: "RAMON BILBAO RESERVA",
            description:
              "Bilbao Reserva is ruby ​​red. Medium-high intensity, with the presence of tears, clean and bright. Frank and complex. With nuances of red fruit, from the fresh vineyards of Rioja Alta. Balsamic and spicy aromas, all of them in perfect balance with the aromas of leather, tobacco leaf and tea after aging in the bottle. It is long, with fresh acidity, creamy and pleasant tannins. In short, a balanced wine, in harmony and ready to enjoy.",
            img: "RAMON_BILBAO_RESERVA.webp",
          },
          {
            name: "SANTA MARGHERITA CHIANTI CLASSICO",
            description:
              "Santa Margherita Chianti Classico is a red, a great wine made in one of the most famous places in Italy. Specifically, Chianti is one of the oldest regions, where vineyards grow on rocky and clay soils (specifically shale and sandstone marls), between valleys and slopes. Chianti Claasico with the aromas of this complex red wine range from cherries and plums to gladiolus flowers and earthy flint. The flavors tannins from the oak aging are bright and round, with a dry finish, warm and earthy.",
            img: "SANTA_MARGHERITA_CHIANTI_CLASSICO.webp",
          },
          {
            name: "SANTA MARGHETITA MERLOT",
            description:
              "In Northern Italy, Veneto is renowned for its red wines from the Merlot grape. The climate there is ideal for making wines with aromas of red fruit and enveloping sensations in the mouth. The wine has a bright ruby ​​red color, which is almost purple when young. Nose: Its aroma is persistent, fine and elegant, with notes of ripe cherries and plums on a background of vanilla and violets. Mouth: On the palate, it is dry, well balanced and velvety, with well integrated tannins. Pairing: Recommended with starters with rich flavors and pasta dishes with salty sauces. It is also a good combination for roasted red meats and aged cheeses.",
            img: "SANTA_MARGHERITA_MERLOT.webp",
          },
          {
            name: "AVGVSTVS CABERNET FRANC",
            description:
              "With a beautiful and intense garnet color with violet tones, its extensive floral aromas, such as rose, lily and violet, along with some vegetal notes (very common in this variety) and very ripe red fruits merge with an elegant, highly integrated wood that It gives it a very special character. With a great structure and character, but above all with great finesse, sweetness and elegance. A curious and different wine, for those who want to know new things.",
            img: "AVGVSTVS_CABERNET_FRANC.webp",
          },
          {
            name: "AVGVSTVS CABERNET SAUVUGNON MERLOT",
            description:
              "An extreme quality wine that mixes a powerful and tasty sweet tannin, with a structured, sweet and very meaty body. Our Avgvstvs Cabernet Sauvignon-Merlot is a complex wine, full of nuances of red fruits and spicy notes, but at the same time, it is a very easy to drink wine. Wine of extreme quality that mixes a powerful and tasty sweet tannin, with a structured, sweet and very meaty body. Versatile and ductile, this Avgvstvs can be enjoyed practically from the moment it is bottled, gaining elegance and finesse as time passes. Pairs with red meats, roasts, game and dry cheese.",
            img: "AVGVSTVS_CABERNET_SAUVIGNON_MERLOT.webp",
          },
          {
            name: "AVGVSTVS ULL DE LLEBRE",
            description:
              "Over Santes Creus town we find sloped and reddish lands. Here the vineyard, calmed, enjoys the most continental warmth of Penedés. Elaborated with delicacy, we obtain a wine with subtle and expressive style. In nose it reminds us like red fruits with a light toasted background. In the mouth, however, we find a lot of power, but at the same time the fluidity and delicacy of this grape variety when it has been made with this style.",
            img: "AVGVSTVS_ULL_DE_LLEBRE.webp",
          },
          {
            name: "TERRELIADE NERO D'AVOLA SYRAH",
            description:
              "Deep ruby ​​red in color, it has aromas of still fresh red fruit and a light but pleasant spiciness. In the mouth it is fine and broad with an agile structure and a nice freshness. Red winemaking involves maceration for approximately two weeks. After malolactic fermentation, the wine is left to refine in steel to favor the varietal aspect that, once in the bottle, harmonizes and blends, providing elegance and pleasantness.",
            img: "TERRELIADE_NERO_DAVOLA_SYRAH.webp",
          },
          {
            name: "CUATRO RAYAS TEMPRANILLO",
            description:
              "Wine from the Land of Castilla y León. Tempranillo from our own organically produced vineyards; aged for 3 months in barrels. Medium layer ruby ​​red color. clean and bright with purple reflections; intense aroma with hints of ripe forest fruits; In the mouth it is soft and velvety, with a good balance between alcohol and acidity, without edges, round and long aftertaste.",
            img: "CUATRO_RAYAS_TEMPRANILLO.webp",
          },
          {
            name: "CRUZ DE ALBA RESERVA FUENTELUN",
            description:
              "A riverside reserve in which the fruit notes and the toasted, chocolatey and sweet spice sensations left by the oak wood are perfectly integrated. Soft, silky, polished and full of elegance although not devoid of drive and liveliness. Deep red in color; aroma of black fruits, chocolate and light toast; highlighting the volume wrapped in a fresh and clean atmosphere that leaves a pleasant wine sensation that is finished off with polished and soft tannin.",
            img: "CRUZ_DE_ALBA_RESERVA_FUENTELUN.webp",
          },
          {
            name: "CRUZ DE ALBA CRIANZA",
            description:
              "Crianza is the essence of all the terroirs of Cruz de Alba. A reading of the potential of the vineyard, the evolution of the vintage and the specific virtues of each plot. Sum of grapes (therefore, characters), with a result that we try to synthesize the power of Tempranillo from Ribera with the vibrant nerve of the vines with balanced soil. Fluid, structured, stimulating. The effort required by biodynamic cultivation seems to obtain the reward of a fresh, slender, well-integrated temperament.",
            img: "CRUZ_DE_ALBA_CRIANZA.webp",
          },
          {
            name: "RAMON BILBAO EDICION LIMITADA",
            description:
              "It is 100% Tempranillo, with soul and fruitiness. Intense ruby ​​red color with garnet reflections. Clean and bright. With the presence of tears when turning the glass. On the nose: clean and frank, with high intensity. Predominance of black fruit (currant, plum, blackberry), licorice, herbal aromas and wood. The passage through the barrel provides noble wood, spicy spices and cocoa. In the mouth: long, intense and with balanced acidity. Presence of round and fine tannin. In retronasal it is reminiscent of the aromas recorded in the nose.",
            img: "RAMON_BILBAO_EDICION_LIMITADA.webp",
          },
          {
            name: "AROMO BARREL SELECTION",
            description:
              "Aromo Barrel Selection is a premium wine with a complex profile, featuring rich notes of dark fruit, vanilla, and oak. Aged in carefully selected barrels, it delivers a smooth, well-rounded flavor with a refined finish. Perfect for pairing with gourmet meals or savoring on its own.",
            img: "AROMO_BARREL_SELECTION.webp",
          },
          {
            name: "AROMO RESERVA SYRAH",
            description:
              "Aromo Reserva Syrah is a robust red wine with bold flavors of ripe berries, black pepper, and a touch of oak. Its full-bodied profile and smooth tannins make it an excellent choice for pairing with grilled meats or enjoying on its own.",
            img: "AROMO_RESERVA_SYRAH.webp",
          },
          {
            name: "AROMO RESERVA SYRAH",
            description:
              "Aromo Reserva Syrah is a robust red wine with bold flavors of ripe berries, black pepper, and a touch of oak. Its full-bodied profile and smooth tannins make it an excellent choice for pairing with grilled meats or enjoying on its own.",
            img: "AROMO_RESERVA_SYRAH.webp",
          },
          {
            name: "CALUCHE CARMENERE",
            description:
              "Caluche Carmenère is a bold red wine with flavors of ripe blackberries, plums, and a hint of spice. Its smooth texture and balanced tannins make it a great match for grilled meats and hearty dishes.",
            img: "CALUCHE_CARMENERE.webp",
          },
          {
            name: "CALUCHE RESERVE MALBEC",
            description:
              "Caluche Reserve Malbec is a rich red wine with intense flavors of dark fruit, chocolate, and subtle oak. Its full-bodied profile and smooth tannins make it perfect for pairing with grilled meats or enjoying on its own.",
            img: "CALUCHE_RESERVE_MALBEC.webp",
          },
          {
            name: "CHATEAU LARTEAU",
            description:
              "Château Larteau is an elegant red wine with complex aromas of ripe fruit, subtle spice, and a hint of oak. Its smooth texture and well-balanced flavors make it an excellent choice for pairing with a variety of dishes or savoring on its own.",
            img: "CHATEAU_LARTEAU.webp",
          },
          {
            name: "CHATEAU SENILHAC",
            description:
              "Château Senilhac is a refined red wine with rich flavors of blackberries, plums, and a touch of spice. Its balanced tannins and smooth finish make it a great companion for roast meats, cheeses, or a sophisticated evening.",
            img: "CHATEAU_SENILHAC.webp",
          },
          {
            name: "CHATEAU TOUR SAINT PIERRE",
            description:
              "Château Tour Saint Pierre is a distinguished red wine with deep flavors of dark fruit, cedar, and subtle spices. Its smooth tannins and elegant finish make it an excellent choice for pairing with grilled meats, hearty dishes, or enjoying on its own.",
            img: "CHATEAU_TOUR_SAINT_PIERRE.webp",
          },
          {
            name: "DOGMA CABERNET SAUVIGNON",
            description:
              "Dogma Cabernet Sauvignon is a bold red wine featuring robust flavors of blackcurrant, dark cherry, and a hint of pepper. Its full-bodied character and smooth tannins make it ideal for pairing with grilled meats, rich sauces, or enjoying on its own.",
            img: "DOGMA_CABERNET_SAUVIGNON.webp",
          },
          {
            name: "TERRELIADE NERO DAVOLA",
            description:
              "Terreliaide Nero d'Avola is a robust red wine with rich flavors of dark cherry, plum, and a touch of spice. Its full body and smooth tannins make it a great match for grilled meats, pasta dishes, or enjoying on its own.",
            img: "TERRELIADE_NERO_DAVOLA.webp",
          },
        ],
      },
      {
        name: "WHITE WINE",
        products: [
          {
            name: "RAMON BILBAO VERDEJO",
            description:
              "Wine of greenish yellow color, medium intensity, clean, bright and with the presence of tears. Intense on the nose with aromas of jasmine, white fruit, anise, citrus and notes of bay leaf, fennel and dried grass. In the mouth it is fresh, with the presence of acidity and length, in perfect alcohol balance. Citrus and floral appear again in the retronasal. Perfect as an aperitif and accompanying cold dishes such as creams, steamed vegetables, salads, creamy cheeses and white meats.",
            img: "RAMON_BILBAO_VERDEJO.webp",
          },
          {
            name: "CUATRO RAYAS VERDEJO",
            description:
              "This dry white Cuatro Rayas Verdejo Pecatis Tuis from Agrícola Castellana is made from a careful selection of the native Verdejo grape variety. The grapes are harvested when the grapes are perfectly ripe. Pale steely yellow color; Fresh and floral aromas predominate on the nose; soft palate and long aftertaste. Pairing: fresh fish and seafood.",
            img: "CUATRO_RAYAS_VERDEJO.webp",
          },
          {
            name: "MAR DE FRADES ALBARIÑO",
            description:
              "Mar de Frades Albariño is a wine that envelops you with its freshness and body, creating a balance between delicate elegance and attractive volume. The nose offers delicious aromas reminiscent of tropical fruits (mango, passion fruit) accompanied by floral and balsamic notes. Its passage through the mouth is refreshing and tasty. A white wine that flows with all the expression of the Albariño variety, accompanied by an exquisite saline and mineral character.",
            img: "MAR_DE_FRADES_ALBARIÑO.webp",
          },
          {
            name: "AROMO VIOGNER",
            description:
              "Aromo Viognier is a vibrant white wine with aromatic notes of peach, apricot, and a hint of floral essence. Its rich, smooth texture and refreshing acidity make it an excellent choice for pairing with seafood, poultry, or as a delightful standalone sip.",
            img: "AROMO_VIOGNER.webp",
          },
          {
            name: "SANTA MARGHERITA PINOT GRIGIO",
            description:
              "Santa Margherita Pinot Grigio is a crisp white wine with vibrant flavors of green apple, pear, and a touch of citrus. Its clean, refreshing profile and balanced acidity make it a versatile choice for pairing with seafood, salads, or enjoying on its own.",
            img: "SANTA_MARGHERITA_PINOT_GRIGIO.webp",
          },
          {
            name: "DOGMA SAUVIGNON BLANC",
            description:
              "Dogma Sauvignon Blanc is a lively white wine with bright flavors of citrus, green apple, and a hint of herbs. Its crisp acidity and refreshing finish make it an excellent choice for pairing with seafood, salads, or enjoying on its own.",
            img: "DOGMA_SAUVIGNON_BLANC.webp",
          },
          {
            name: "TORRESELLA CHARDONNAY",
            description:
              "Torresella Chardonnay is a smooth white wine with flavors of ripe apple, pear, and a touch of vanilla. Its balanced acidity and subtle oak notes make it ideal for pairing with poultry, seafood, or creamy dishes.",
            img: "TORRESELLA_CHARDONNAY.webp",
          },
          {
            name: "TORRESELLA PINOT GRIGIO",
            description:
              "Torresella Pinot Grigio is a crisp and refreshing white wine with vibrant notes of citrus, green apple, and pear. Its clean finish and balanced acidity make it a perfect match for seafood, salads, or enjoying on its own.",
            img: "TORRESELLA_PINOT_GRIGIO.webp",
          },
        ],
      },
      {
        name: "ROSE WINE",
        products: [
          {
            name: "RAMON BILBAO ROSADO",
            description:
              "Ramón Bilbao Rosado is a rosé wine from the D.O. Rioja made by Bodegas Ramon Bilbao. Ramón Bilbao Rosado 2022 is a wine that on the nose offers pleasant aromas of citrus (orange peel) and red fruits (strawberries, cherries) accompanied by herbal notes. In the mouth it flows light and refreshing, with vibrant acidity. A very pleasant rosé wine, balanced and easy to drink.",
            img: "RAMON_BILBAO_ROSADO.webp",
          },
          {
            name: "SANTA MARGHERITA STILROSE",
            description:
              "The delicate pink color introduces the floral fragrances of rose hips and the fruity notes of orange and wild strawberries with touches of lychees and ginger in an elegant and varied olfactory expression. The softness of the perfumes gradually manifests itself in a gustatory freshness of tasty succulence, which pulses with a sapidity that drives the crisp aromatic notes of berries and sweet spices.",
            img: "SANTA_MARGHERITA_STILROSE.webp",
          },
          {
            name: "MINUTY COTES DE PROVENCE ROSE",
            description:
              "Château Minuty M de Minuty Côtes de Provence Rosé is a delightful French wine that is perfect for any occasion. The wine has a beautiful pale pink color and a nose that is full of fragrant roses, white peach, and and citrus fruits. On the palate, you will find a crisp, refreshing taste that is light and airy, with hints of red berries, peach, and apricot. The wine's acidity is perfectly balanced, making it an ideal wine to drink on its own or paired with a variety of dishes, including seafood, salads, and grilled meats.",
            img: "MINUTY_COTES_DE_PROVENCE_ROSE.webp",
          },
          {
            name: "SPARKLING ROSE",
            description:
              "Sparkling Rosé is a refreshing wine with bright flavors of red berries, citrus, and a hint of floral notes. Its effervescent bubbles and crisp acidity make it perfect for celebrating or enjoying as a light, elegant aperitif.",
            img: "SPARKLING_ROSE.webp",
          },
        ],
      },
      {
        name: "SPARKLING",
        products: [
          {
            name: "LUC BELAIRE FANTOME",
            description:
              "Luc Belaire Fantôme is a premium sparkling wine with vibrant flavors of citrus, berries, and a touch of floral notes. Its crisp bubbles and elegant finish make it ideal for celebrations or as a refined aperitif.",
            img: "LUC_BELAIRE_FANTOME.webp",
          },
          {
            name: "LUC BELAIRE LUXE",
            description:
              "Luc Belaire Luxe is a refined sparkling wine with rich notes of ripe fruit, honey, and a hint of vanilla. Its smooth bubbles and sophisticated flavor profile make it perfect for special occasions or as a luxurious treat.",
            img: "LUC_BELAIRE_LUXE.webp",
          },
          {
            name: "LUC BELAIRE ROSE",
            description:
              "Luc Belaire Rosé is a vibrant sparkling wine with lively notes of red berries, citrus, and a touch of floral elegance. Its fresh bubbles and crisp taste make it a delightful choice for celebrations or enjoying on a sunny day.",
            img: "LUC_BELAIRE_ROSE.webp",
          },
          {
            name: "SPARKLING ROSE",
            description:
              "Sparkling Rosé is a refreshing wine with bright flavors of red berries, citrus, and a hint of floral notes. Its effervescent bubbles and crisp acidity make it perfect for celebrating or enjoying as a light, elegant aperitif.",
            img: "SPARKLING_ROSE.webp",
          },
          {
            name: "TORRESELLA PROSECCO",
            description:
              "Torresella Prosecco is a sparkling wine with fresh, lively flavors of green apple, pear, and a hint of citrus. Its delicate bubbles and crisp, clean finish make it ideal for celebrations or as a refreshing aperitif.",
            img: "TORRESELLA_PROSECCO.webp",
          },
        ],
      },
    ],
  },

  {
    id: "groceries",
    category: "GROCERIES",
    descriptionMini:
      "A diverse range of high-quality groceries, providing essential and specialty items for all your needs.",
    description:
      "Our 'Groceries' category features a wide variety of high-quality products, including essential staples and specialty items. From everyday necessities like grains, dairy, and canned goods to unique gourmet products and international ingredients, our selection is designed to meet all your culinary needs. Each item is chosen for its quality and freshness, ensuring you have access to the best ingredients for your cooking and baking endeavors. Whether you're stocking up your pantry or searching for something special, our diverse range of groceries offers convenience and excellence for every meal.",
    img: "groceries.png",
    subcategories: [
      {
        name: "DRIED FRUITS",
        products: [
          {
            name: "CRAISINS DRIED CRANBERRIES",
            description:
              "Surprise your taste buds with the sweet, delicious taste of Craisins® Dried Cranberries. Made with real fruit and good as a snack or in a recipe, they’re gluten free. Plus, they’re a good source of fiber and 1 serving of Craisins® Original Dried Cranberries meets 25% of your daily recommended fruit needs.",
            img: "CRAISINS_DRIED_CRANBERRIES.webp",
          },
        ],
      },
      {
        name: "SAUCES",
        products: [
          {
            name: "YUCATECO CARIBBEAN HOT SAUCE",
            description:
              "Yucateco Caribbean Hot Sauce blends fiery habanero peppers with a hint of tropical fruit. It delivers a vibrant heat and tangy flavor that enhances a variety of dishes. Perfect for adding a spicy kick to your favorite meals.",
            img: "YUCATECO_CARIBBEAN_HOT_SAUCE.webp",
          },
        ],
      },
    ],
  },
];
