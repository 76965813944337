import all from "../../../assets/icons/brandAll.svg";
import spirits from "../../../assets/icons/brandTequila.svg";
import beers from "../../../assets/icons/branBeer.svg";
import wines from "../../../assets/icons/branWine.svg";
import waters from "../../../assets/icons/brandWater.svg";
import groceries from "../../../assets/icons/brandSnacks.svg";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { BrandsData } from "src/data/brands";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import { Grid, Pagination } from "swiper/modules";

export default function Brands() {
  const [brandSelected, setBrandSelected] = useState<string>("");
  const [brandsCopy, setBrandsCopy] = useState<Array<any>>([]);

  useEffect(() => {
    setBrandsCopy([]);
    if (brandSelected === "all") {
      setBrandsCopy(BrandsData);
    } else {
      setBrandsCopy(
        BrandsData.filter((brands) => brands.category.includes(brandSelected))
      );
    }
  }, [brandSelected]);

  return (
    <div>
      <div className="text-primary text-center bgBrands py-20">
        <h2 className="text-2xl font-bold px-[5%]">Our Brands</h2>
        <p className="mt-4 px-[5%]">
          We focus on giving life to brands, in order to distribute the best
          quality products. Explore below some of the brands we manage:
        </p>

        <div>
          <div className="mt-8 sm:mt-16 overflow-scroll px-5 w-full sm:pl-0 sm:overflow-hidden flex flex-row sm:flex-wrap gap-10 justify-start sm:justify-center [&>div]:flex [&>div]:flex-col [&>div]:gap-3 [&>div]:justify-center [&>div]:font-bold [&>div]:text-lg [&>div]:hover:cursor-pointer ">
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("all")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={all}
                alt=""></img>
              <p>All</p>
            </div>
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("spirits")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={spirits}
                alt=""></img>
              <p>Spirits</p>
            </div>
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("beers")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={beers}
                alt=""></img>
              <p>Beers</p>
            </div>
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("wines")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={wines}
                alt=""></img>
              <p>Wines</p>
            </div>
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("w&j")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={waters}
                alt=""></img>
              <p>Waters</p>
            </div>
            <div
              className="hover:underline"
              onClick={() => setBrandSelected("groceries")}>
              <img
                className="bg-primary p-3 rounded-md min-w-[75px] h-[80px]"
                src={groceries}
                alt=""></img>
              <p>Groceries</p>
            </div>
          </div>
        </div>
        {brandSelected !== "" ? (
          // <div className="w-[80%] max-w-[1000px] mt-10 mx-auto grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          //   {brandsCopy.map((brand, key: number) => (
          //     <div
          //       key={key}
          //       className={
          //         "py-12 sm:py-16 px-4 sm:px-8 rounded-md text-center flex justify-center bg-white object-contain hover:shadow-md"
          //       }>
          //       <img
          //         className="object-contain w-full h-28"
          //         src={require("../.././../assets/brands/" + brand.img)}
          //         alt={brand.img.split(".")[0]}></img>
          //     </div>
          //   ))}
          // </div>
          <Swiper 
            slidesPerView={2}
            slidesPerGroup={2}
            grid={{
              rows: 4,
              fill: "row",
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Grid, Pagination]}
            breakpoints={{
              640: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              1024: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
            }}
            className="swiperBrands max-w-[1000px] mt-10">
            {brandsCopy.map((brand, key: number) => (
              <SwiperSlide key={key}>
                <div
                  className={
                    "py-12 sm:py-16 px-4 sm:px-8 rounded-md text-center flex justify-center bg-white object-contain hover:shadow-md"
                  }>
                  <img
                    className="object-contain w-full h-28"
                    src={require("../.././../assets/brands/" + brand.img)}
                    alt={brand.img.split(".")[0]}></img>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="w-full text-center flex justify-center">
            <Link
              to={"/ourProducts"}
              className="mt-16 text-xl font-semibold flex text-center items-center justify-center w-fit gap-3 group hover:border-b-[1px] hover:border-primary hover:cursor-pointer">
              Explore Brands{" "}
              <ArrowRightIcon className="w-5 h-5 group-hover:w-9 group-hover:h-7"></ArrowRightIcon>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
