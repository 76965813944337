import { ArrowRightIcon } from "@radix-ui/react-icons";
import icon1 from "../../../assets/icons/icon1.svg";
import icon2 from "../../../assets/icons/icon2.svg";
import icon3 from "../../../assets/icons/icon3.svg";
import icon4 from "../../../assets/icons/icon4.svg";
import CountUp from 'react-countup';
import { Link } from "react-router-dom";

export default function Experience() {
  return (
    <div className="md:grid md:grid-cols-[2fr_3fr] py-10 md:py-20 px-[5%] mx-auto max-w-[1400px]">
      <div className="relative">
        <img
          className="absolute hidden md:block top-[10%] w-[45%] lg:w-48"
          src={require("../../../assets/home/beer.png")} alt=""></img>
        <img
          className="absolute hidden md:block lg:top-[160px] top-[32.5%] w-[45%] left-[40%] lg:w-48 lg:left-[140px]"
          src={require("../../../assets/home/ron.png")} alt=""></img>
      </div>
      <div>
        <h2 className="font-bold text-2xl">Our 16 years of experience</h2>
        <p>
          We are specialized in marketing and expert in the distribution,
          focused on the development of leading brands worldwide.
        </p>
        <div>
          <div className="grid grid-cols-2 mt-5 gap-y-7 md:mt-10 md:gap-y-14 [&>div]:items-center">
            <div className="flex gap-2 sm:gap-4">
              <div className="py-2 sm:py-4 px-2 rounded-md bg-primary w-fit flex items-center">
                <img className="w-10 h-10 sm:w-14 sm:h-10" src={icon1} alt=""></img>
              </div>
              <div>
                <h3 className="text-2xl font-bold">+ <CountUp useEasing={true} delay={1} duration={3} end={100} /></h3>
                <p className="text-[13px] sm:text-sm">Collaborators</p>
              </div>
            </div>
            <div className="flex gap-2 sm:gap-4">
              <div className="py-2 sm:py-4 px-2 rounded-md bg-primary w-fit flex items-center">
                <img className="w-10 h-8 sm:w-14 sm:h-10" src={icon2} alt=""></img>
              </div>
              <div>
                <h3 className="text-2xl font-bold">+<CountUp useEasing={true} delay={1} duration={4} end={30} /></h3>
                <p className="text-[13px] sm:text-sm">Brands</p>
              </div>
            </div>



            <div className="flex gap-2 sm:gap-4">
              <div className="py-4 px-2 rounded-md bg-primary w-fit flex items-center">
                <img className="w-10 h-7 sm:w-14 sm:h-10" src={icon3} alt=""></img>
              </div>
              <div>
                <h3 className="text-2xl font-bold">+<CountUp useEasing={true} delay={1} duration={4} end={4000} /></h3>
                <p className="text-[13px] sm:text-sm">Clients</p>
              </div>
            </div>
            <div className="flex gap-2 sm:gap-4">
              <div className="py-4 px-2 rounded-md bg-primary w-fit flex items-center">
                <img className="w-10 h-7 sm:w-14 sm:h-10" src={icon4} alt=""></img>
              </div>
              <div>
                <h3 className="text-2xl font-bold">+<CountUp useEasing={true} delay={1} duration={8} end={6} /></h3>
                <p className="text-[13px] sm:text-sm">Countries</p>
              </div>
            </div>
          </div>
          <Link to={"/aboutUs"} className="mt-10 justify-center sm:mt-20 flex flex-row sm:justify-start items-center gap-3 font-bold w-fit text-xl group hover:border-b-[1px] hover:border-black hover:cursor-pointer">
            About Us <ArrowRightIcon className="w-5 h-5 group-hover:w-9 group-hover:h-7"></ArrowRightIcon>
          </Link>
        </div>
      </div>
    </div>
  );
}
