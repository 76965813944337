import logo from "../assets/logo.svg";
import s1 from "../assets/icons/s1.svg";
import s2 from "../assets/icons/s2.svg";
import s3 from "../assets/icons/s3.svg";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { CategoriesData } from "src/data/categories";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div className="pt-9 bg-[#515151] px-[5%] text-white ">
        <div className="max-w-[1400px] md:mx-auto md:flex md:flex-row md:gap-[7%]">
          <div className="flex justify-between items-center md:flex-col md:justify-start md:items-start md:gap-y-5">
            <img src={logo} alt=""></img>
            <div className="flex gap-6 [&>img]:w-6 [&>img]:h-6">
              <img src={s2} alt=""></img>
              <img src={s3} alt=""></img>
              <img src={s1} alt=""></img>
            </div>
          </div>

          <div className="md:hidden pb-10">
            <Accordion type="single" collapsible className="w-full">
              {CategoriesData.map((category, index) => {
                return (
                  <AccordionItem key={index} value={"item-" + index}>
                    <AccordionTrigger className="uppercase">
                      {category.category}
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="flex flex-col gap-2 text-xs [&>li]:mb-2">
                        {category.subcategories.map((subcategory, index) => {
                          return (
                            <Link key={index}
                              to={
                                "/ourProducts/" +
                                category.category.toLocaleLowerCase() +
                                "/" +
                                subcategory.name.toLocaleLowerCase()
                              }>
                              {subcategory.name}
                            </Link>
                          );
                        })}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}

              <AccordionItem value="item-6">
                <AccordionTrigger>DIRECTORY</AccordionTrigger>
                <AccordionContent>
                  <ul className="text-xs [&>li]:mb-2">
                    <li>EL SALVADOR</li>
                    <li>BELIZE</li>
                    <li>PANAMÁ</li>
                    <li>GUATEMALA</li>
                    <li>HONDURAS</li>
                    <li>COSTA RICA</li>
                    <li>NICARAGUA</li>
                  </ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="hidden md:flex md:flex-row md:gap-[8%] lg:gap-[20%] xl:gap-[30%] pb-10">
            {CategoriesData.map((category, index) => {
              if (index === 0) {
                return (
                  <div key={index}>
                    <div>
                      <h3 className="font-bold mb-3">{category.category}</h3>
                      <ul className="flex flex-col gap-2 text-xs [&>li]:mb-2">
                        {category.subcategories.map((subcategory,index) => {
                          return (
                            <Link key={index}
                              to={
                                "/ourProducts/" +
                                category.category.toLocaleLowerCase() +
                                "/" +
                                subcategory.name.toLocaleLowerCase()
                              }>
                              {subcategory.name}
                            </Link>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              }
            })}

            <div className="flex flex-col justify-between">
              {CategoriesData.map((category, index) => {
                if (index > 0 && index < 3) {
                  return (
                    <div key={index}>
                      <h3 className="font-bold mb-3">{category.category}</h3>
                      <ul className="flex flex-col gap-2 text-xs [&>li]:mb-2">
                        {category.subcategories.map((subcategory, index) => {
                          return (
                            <Link key={index}
                              to={
                                "/ourProducts/" +
                                category.category.toLocaleLowerCase() +
                                "/" +
                                subcategory.name.toLocaleLowerCase()
                              }>
                              {subcategory.name}
                            </Link>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }
              })}

              {/* <div>
                <h3 className="font-bold mb-3 mt-11">WINES</h3>
                <ul className="text-xs [&>li]:mb-2">
                  <li>SPARKLING</li>
                  <li>SANGRIA</li>
                  <li>TINT</li>
                  <li>WHITE</li>
                </ul>
              </div> */}
            </div>

            <div className="flex flex-col justify-between">
              {CategoriesData.map((category, index) => {
                if (index > 2 && index < 5) {
                  return (
                    <div key={index}>
                      <h3 className="font-bold mb-3">{category.category}</h3>
                      <ul className=" flex flex-col gap-2 text-xs [&>li]:mb-2">
                        {category.subcategories.map((subcategory, index) => {
                          return (
                            <Link key={index}
                              to={
                                "/ourProducts/" +
                                category.category.toLocaleLowerCase() +
                                "/" +
                                subcategory.name.toLocaleLowerCase()
                              }>
                              {subcategory.name}
                            </Link>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }
              })}
              {/* <div>
                <h3 className="font-bold mb-3">WATER & JUICES</h3>
                <ul className="text-xs [&>li]:mb-2">
                  <li>TONIC</li>
                  <li>WATER</li>
                  <li>MIXERS</li>
                  <li>JUICES</li>
                </ul>
              </div>

              <div>
                <h3 className="font-bold mb-3 mt-11">GROCERIES</h3>
                <ul className="text-xs [&>li]:mb-2">
                  <li>HOT CHILIS</li>
                </ul>
              </div> */}
            </div>

            <div>
              <h3 className="font-bold mb-3">DIRECTORY</h3>
              <ul className="text-xs [&>li]:mb-2">
                <li>EL SALVADOR</li>
                <li>BELIZE</li>
                <li>PANAMÁ</li>
                <li>GUATEMALA</li>
                <li>HONDURAS</li>
                <li>COSTA RICA</li>
                <li>NICARAGUA</li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-3">COMPANY</h3>
              <ul className="flex flex-col gap-2 text-xs [&>li]:mb-2">
                <Link to={"/aboutUs"}>ABOUT US</Link>
                <Link to={"/ourProducts"}>OUR BRANDS</Link>
                <Link to={"/contactUs"}>CONTACT US</Link>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black text-white px-[5%] text-center text-sm py-6">
        Copyright © 2024 All rights reserved PremierTec by ♥{" "}
        <a className="underline" href="https://www.premte.com/">
          PremTe
        </a>
      </div>
    </div>
  );
}
