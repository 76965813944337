import { CategoriesData } from "src/data/categories";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../components/ui/navigation-menu";

import { Link } from "react-router-dom";
export default function NavBarMain() {
  return (
    <div className="hidden md:block">
      <div className="text-white flex justify-end pr-5 mt-7 gap-4  lg:[&>a]:text-base lg:[&>a]:px-4 [&>a]:px-4 [&>a]:py-1 [&>a]:rounded-md [&>a]:font-bold">
        <Link to={"/"} className={window.location.href.includes('home') ? 'bg-[#003459]' : 'bg-black'}>
          HOME
        </Link>
        <Link to={"/aboutUs"} className={window.location.href.includes('aboutUs') ? 'bg-[#003459]' : 'bg-black'}>
          ABOUT US
        </Link>
        <NavigationMenu className="static">
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className={"hover:bg-black hover:text-white focus:bg-black focus:text-white "  + (window.location.href.includes('ourProducts') ? 'bg-[#003459]' : 'bg-black')}>
                <Link className="lg:text-base font-bold " to={"/ourProducts"}>
                  OUR PRODUCTS
                </Link>
              </NavigationMenuTrigger>
              <NavigationMenuContent className=" border-none w-full pb-6 bg-opacity-90 bg-[#0B0B0B] text-white px-[5%] md:w-[90vw] md:max-w-[1200px]">
                {CategoriesData.map((category, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex gap-8 items-center border-b-2 pb-4 mt-6">
                      <div>
                        <h3 className="font-bold text-xl uppercase hover:text-[#57B9FF]">
                          <Link to={category.id}>{category.category}</Link>
                        </h3>
                      </div>

                      <div className="flex gap-4 [&>div]:text-sm">
                        {category.subcategories.map((subcategory, index2) => {
                          return (
                            <div className="uppercase hover:text-[#57B9FF]" key={index2}>
                              <Link to={'/ourProducts/' + category.id + '/' + subcategory.name.toLocaleLowerCase()}>{subcategory.name}</Link>
                            </div>
                          );
                        })}
                        
                      </div>
                    </div>
                  );
                })}
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>

        <Link to={"/contactUs"} className={window.location.href.includes('contactUs') ? 'bg-[#003459]' : 'bg-black'}>
          CONTACT US
        </Link>
      </div>
    </div>
  );
}
