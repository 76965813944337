import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

export default function Companies() {
  return (
    <div className="text-white text-center px-[5%] py-20 bg-[#0B0B0B]">
      <h2 className="text-2xl font-bold">Our Companies</h2>
      <p className="mt-4">
        Throught the years we have created powerfull global corporate brands
        also we represent companies with real values, they are buit around great
        consumer brands
      </p>

      <div className="mt-10 justify-center sm:mt-20 flex flex-row flex-wrap items-center gap-3 gap-y-5 font-bold text-xl ">
        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/ElSalvador.png")}
            alt="El Salvador"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">El Salvador</h3>
              <p className="text-white text-xs">Founded 2009</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/Belize.png")}
            alt="Belize"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Belize</h3>
              <p className="text-white text-xs">Founded 2015</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/Panama.png")}
            alt="Panama"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Panamá</h3>
              <p className="text-white text-xs">Founded 2016</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/Honduras.png")}
            alt="Honduras"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Honduras</h3>
              <p className="text-white text-xs">Founded 2017</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/Guatemala.png")}
            alt="Guatemala"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Guatemala</h3>
              <p className="text-white text-xs">Founded 2017</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/CostaRica.png")}
            alt="Costa Rica"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Costa Rica</h3>
              <p className="text-white text-xs">Founded 2019</p>
            </div>
          </div>
        </div>

        <div className="group relative">
          <img
            className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
            src={require("../../../assets/countryFlags/Nicaragua.png")}
            alt="Nicaragua"
          />
          {/* <div className="mt-2 lg:h-[0px] lg:group-hover:h-[45px] lg:transition-height"></div> */}
          <div className="mt-2">
            <div className="text-center lg:opacity-0 lg:translate-x-9 lg:group-hover:opacity-100 lg:group-hover:translate-x-[0%] lg:duration-1000">
              <h3 className="text-white">Nicaragua</h3>
              <p className="text-white text-xs">Founded 2023</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center">
      <Link to={"/contactUs"} className="font-semibold text-lg mt-10 flex justify-center items-center gap-4 group hover:border-b-[1px] w-fit hover:border-white hover:cursor-pointer">
          Contact Us <ArrowRightIcon className="w-5 h-5 group-hover:w-9 group-hover:h-7"></ArrowRightIcon>
        </Link>
      </div>
    </div>
  );
}
