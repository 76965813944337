import { Button } from "./ui/button";
import { Input } from "./ui/input";

export default function NewsLetter() {
  return (
    <div className="text-white text-center  bg-[#000000]">
      <div className="px-[5%] max-w-[1400px] mx-auto pt-20 pb-64 bgNewsletter">
        <h2 className="text-2xl font-bold">STAY UPDATE</h2>
        <p className="mt-4">
          Send us your email and you will receive our portfolio. Check all the
          products we have for you
        </p>
        <div className="flex flex-col sm:flex-row gap-3 justify-center mt-10 mx-[20%]">
          <Input
            className="bg-[#515151] border-none h-16 sm:w-[90%] min-w-[200px] placeholder:text-center placeholder:text-white"
            placeholder="WRITE YOUR EMAIL HERE"></Input>
          <Button className="h-16 sm:w-[15%] font-extrabold">SEND</Button>
        </div>
      </div>
    </div>
  );
}
