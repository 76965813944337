import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

export default function HeroBanner() {
  return (
    <div className="relative bgHeroBanner">
      <div className="text-white pt-14 sm:pt-20 mx-auto px-[5%] max-w-[1440px] flex flex-row sm:grid grid-cols-[2fr_1fr] sm:grid-cols-[3fr_3fr] sm:gap-4 relative items-center">
        <div className="flex flex-col gap-6 w-[60%] sm:w-auto">
          <h1 className="text-4xl font-bold">Inspired by excellence</h1>
          <p>
            A single place to distribute, create and discover brands that tells
            a story
          </p>

          <Link to={"/ourProducts"} className="flex gap-2 font-semibold text-xl items-center w-fit group hover:border-b-[1px] hover:border-white hover:cursor-pointer">
            Our Products <ArrowRightIcon className="w-5 h-5 group-hover:w-9 group-hover:h-7"></ArrowRightIcon>
          </Link>
        </div>
        <div className="sm:relative flex flex-row sm:h-[400px]">
          <img
            className="absolute rounded-xl max-h-[400px] w-[35%] z-10 max-w-[120px] h-auto bottom-0 left-[60%] object-cover sm:left-5 sm:top-auto sm:max-w-[200px] sm:h-auto sm:w-[70%] sm:rounded-3xl md:max-w-[220px] lg:w-[350px] lg:max-w-[290px] lg:bottom-0 lg:top-auto xl:w-[320px] xl:max-w-max"
            src={require("../../../assets/home/imgHero1.png")}
            alt=""></img>
          <img
            className="absolute rounded-xl w-[25%] max-w-[100px] h-auto top-[40px] left-[70%] object-cover sm:left-[35%] sm:max-w-[150px] sm:w-[60%] sm:h-auto md:w-[45%] md:max-w-[180px] md:left-[225px] lg:w-[430px] xl:w-[270px] lg:max-w-[190px] lg:max-h-[300px] lg:left-[310px] sm:top-0 sm:rounded-3xl xl:left-[350px]"
            src={require("../../../assets/home/imgHero2.png")}
            alt=""></img>
        </div>
      </div>
    </div>
  );
}
