import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoBlue from "../assets/logoBlue.svg";
import NavBarMain from "./navbarMain";
import NavBarMobile from "./navbarMobile";

export default function Header() {
  return (
    <div className="">
      <div
        className={
          "fixed z-50 top-8 left-6 " +
          (!window.location.href.includes("ourProducts") ? "mix-blend-difference" : "")
        }>
          <Link to={"/"}><img
          src={!window.location.href.includes("ourProducts") ? logo : logoBlue}
          alt=""
        /></Link>
        
      </div>
      <div className="bg-[#FAFAFA] fixed z-40 h-20 w-full  bg-opacity-[2%] backdrop-blur-[3px] mix-blend-difference md:mix-blend-normal">
        <div className="relative">
          <div>
            <NavBarMain></NavBarMain>
            <NavBarMobile></NavBarMobile>
          </div>
        </div>
      </div>

      {/* <div className="mx-auto fixed px-[5%] max-w-[1400px] flex flex-row justify-between">
        <div className="fixed z-50 mix-blend-difference"><img src={logo} alt="logo" className="logo-img"></img></div>
        <NavBarMain></NavBarMain>
        <NavBarMobile></NavBarMobile>
      </div> */}
    </div>
  );
}
