import Layout from "src/components/layout";
import HeaderProducts from "./components/header";
import CardCategory from "src/components/cardCategory";
import { CategoriesData } from "src/data/categories";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductsHeader from "./components/productsHeader";
import NewsLetter from "src/components/newsletter";

export default function OurProducts() {
  const params = useParams();
  const [categoryPageCopy, setCategoryPageCopy] = useState<Array<any>>([]);
  const [category, setCategory] = useState<any>(undefined);
  const [subcategory, setSubCategory] = useState<any>(undefined);

  useEffect(() => {
    console.log(window.location.href);
    if (params.category !== undefined) {
      const categoryFinded = CategoriesData.filter(
        (category) => category.id === params.category
      )[0];

      if (categoryFinded !== undefined) {
        setCategory(categoryFinded);
        const subCategoryFinded = categoryFinded.subcategories.filter(
          (subcategory) =>
            subcategory.name.toLocaleLowerCase() === params.subcategory
        )[0];
        setSubCategory(subCategoryFinded);
      }
    }
    setCategoryPageCopy(
      CategoriesData.filter((category) => category.id !== params.category)
    );
  }, [params]);

  return (
    <Layout>
      {category === undefined ? (
        <HeaderProducts></HeaderProducts>
      ) : (
        <div className="pb-6 pt-32 bg-[#FAFAFA] px-[5%] ">
          <div className="flex flex-col md:flex-row md:flex-nowrap max-w-[1400px] mx-auto">
            <div className="md:w-[30%]">
              <h1 className="text-3xl font-bold mb-4 text-[#003459]">
                {category?.category}
              </h1>
              <div className="flex gap-4 flex-wrap items-center md:block">
                {category.subcategories.map(
                  (subcategoryMap: any, index: number) => (
                    <p
                      onClick={() => setSubCategory(subcategoryMap)}
                      className={
                        "hover:text-[#0597FF] hover:cursor-pointer uppercase mt-1 " +
                        (subcategory && subcategoryMap.name === subcategory.name
                          ? "text-[#0597FF] text-lg my-[2px]"
                          : "text-[#003459]")
                      }
                      key={index}>
                      {subcategoryMap.name}
                    </p>
                  )
                )}
              </div>
            </div>
            <div className="w-full md:w-[70%]">
              {subcategory !== undefined ? (
                <ProductsHeader
                  products={subcategory.products}
                  category={category.id}></ProductsHeader>
              ) : (
                <div className="flex flex-col md:flex-row relative mt-[240px] md:mt-0">
                  <div className="bg-[#57B9FF] w-full md:left-[16%] md:w-[84%] mt-5 rounded-2xl absolute bluebgProducts"></div>
                  <div className="h-[360px] absolute -top-48 md:left-0 md:top-0 md:relative md:h-auto w-full md:w-[30%]">
                    <div className="absolute w-[100%] md:top-0 h-full">
                      <img
                        className="object-contain w-full h-full"
                        src={require(`../../assets/categories/${category.img}`)}
                        alt={"info.name"}></img>
                    </div>
                  </div>

                  <div className="relative mt-[200px] md:mt-0 px-[5%] md:px-0 md:w-[65%] md:py-10">
                    <div className="text-white text-center md:text-left md:pt-4 pb-12">
                      <div className="flex flex-col gap-4 ">
                        <p>{category.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="py-6 px-[5%]">
        <div className="mx-auto max-w-[1400px] grid grid-cols-1 md:grid-cols-2 gap-x-5">
          {categoryPageCopy.map((category: any, index: number) => {
            return (
              <CardCategory
                key={index}
                id={category.id}
                name={category.category}
                image={category.img}
                miniDescription={category.descriptionMini}></CardCategory>
            );
          })}
        </div>
      </div>
      <NewsLetter></NewsLetter>
    </Layout>
  );
}
