export const BrandsData = [
  {
    category: ["spirits"],
    img: "abasolo.svg",
  },
  {
    category: ["w&j"],
    img: "AcquaPanna.svg",
  },
  {
    category: ["spirits"],
    img: "anchoReyes.svg",
  },
  {
    category: ["spirits"],
    img: "angostura.svg",
  },
  {
    category: ["wines"],
    img: "aromo.svg",
  },
  {
    category: ["beers"],
    img: "asahi.svg",
  },
  {
    category: ["wines"],
    img: "avgvstvs.svg",
  },
  {
    category: ["wines"],
    img: "belaire.svg",
  },
  {
    category: ["spirits"],
    img: "bols.svg",
  },
  {
    category: ["spirits"],
    img: "bulldog.svg",
  },
  {
    category: ["wines"],
    img: "caleuche.svg",
  },
  {
    category: ["beers"],
    img: "cerveceria14.svg",
  },
  {
    category: ["wines"],
    img: "chateauLarteau.svg",
  },
  {
    category: ["wines"],
    img: "chateauSenilhac.svg",
  },
  {
    category: ["spirits"],
    img: "chinola.svg",
  },
  {
    category: ["spirits"],
    img: "cihuatan.svg",
  },
  {
    category: ["wines"],
    img: "cruzDeAlba.svg",
  },
  {
    category: ["spirits"],
    img: "cuttySark.svg",
  },
  {
    category: ["beers"],
    img: "delirium.svg",
  },
  {
    category: ["wines"],
    img: "dogma.svg",
  },
  {
    category: ["spirits"],
    img: "domaineDeCanton.svg",
  },
  {
    category: ["spirits"],
    img: "drambuie.svg",
  },
  {
    category: ["spirits"],
    img: "elRecuerdo.svg",
  },
  {
    category: ["spirits"],
    img: "espolon.svg",
  },
  {
    category: ["spirits"],
    img: "evanWilliams.svg",
  },
  {
    category: ["spirits"],
    img: "glenfiddich.svg",
  },
  {
    category: ["spirits"],
    img: "grandMarnier.svg",
  },
  {
    category: ["spirits"],
    img: "granMalo.svg",
  },
  {
    category: ["spirits"],
    img: "grants.svg",
  },
  {
    category: ["beers"],
    img: "grolsch.svg",
  },
  {
    category: ["spirits"],
    img: "hpnotiq.svg",
  },
  {
    category: ["spirits"],
    img: "jagermeister.svg",
  },
  {
    category: ["spirits"],
    img: "licor43.svg",
  },
  {
    category: ["spirits"],
    img: "lolea.svg",
  },
  {
    category: ["spirits"],
    img: "luxardo.svg",
  },
  {
    category: ["beers"],
    img: "mahou.svg",
  },
  {
    category: ["wines"],
    img: "marDeFrades.svg",
  },
  {
    category: ["spirits"],
    img: "martinMillers.svg",
  },
  {
    category: ["wines"],
    img: "minuty.svg",
  },
  {
    category: ["spirits"],
    img: "monkeyShoulder.svg",
  },
  {
    category: ["spirits"],
    img: "nixta.svg",
  },
  {
    category: ["spirits"],
    img: "nuvo.svg",
  },
  {
    category: ["w&j", "groceries"],
    img: "oceanSpray.svg",
  },
  {
    category: ["spirits"],
    img: "ojoDeTigre.svg",
  },
  {
    category: ["spirits"],
    img: "pama.svg",
  },
  {
    category: ["beers"],
    img: "peroni.svg",
  },
  {
    category: ["w&j"],
    img: "perrier.svg",
  },
  {
    category: ["spirits"],
    img: "PuertoDeIndias.svg",
  },
  {
    category: ["wines"],
    img: "ramonBilbao.svg",
  },
  {
    category: ["w&j"],
    img: "sanPellegrino.svg",
  },
  {
    category: ["wines"],
    img: "santaMargherita.svg",
  },
  {
    category: ["wines"],
    img: "stambeccoItaly.svg",
  },
  {
    category: ["spirits"],
    img: "suze.svg",
  },
  {
    category: ["spirits"],
    img: "tequilaOcho.svg",
  },
  {
    category: ["wines"],
    img: "terreliade.svg",
  },
  {
    category: ["spirits"],
    img: "theGlenrothes.svg",
  },
  {
    category: ["spirits"],
    img: "titos.svg",
  },
  {
    category: ["wines"],
    img: "torresella.svg",
  },
  {
    category: ["spirits"],
    img: "tullamoreDew.svg",
  },
  {
    category: ["groceries"],
    img: "yucateco.svg",
  },
];
