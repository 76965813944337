import { useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { Button } from "./ui/button";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { CategoriesData } from "src/data/categories";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordionHeader";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

export default function NavBarMobile() {
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden fixed right-5 top-5 hover:bg-transparent">
          <HamburgerMenuIcon className="w-7 h-7 text-white" />
        </Button>
      </SheetTrigger>

      <SheetContent className="p-0 bg-[#0B0B0B] rounded-b-lg backdrop-blur-[3px] bg-opacity-80 w-full h-fit py-7 text-white px-4">
        <div className="mt-[2px] ml-2 mb-6">
          <img src={logo} alt=""></img>
        </div>
        <div className="mb-4">
          <Link className={"uppercase font-bold text-xl " + (window.location.href.includes('home') ? 'text-[#0597FF]' : '')} to={"/home"}>
            Home
          </Link>
        </div>
        <div className="mb-1">
          <Link className={"uppercase font-bold text-xl " + (window.location.href.includes('aboutUs') ? 'text-[#0597FF]' : '')} to={"/aboutUs"}>
            About us
          </Link>
        </div>
        {CategoriesData.map((category, index: number) => {
          return (
            <Accordion type="single" key={index} collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="[&[data-state=open]>div]:text-[#0597FF] [&[data-state=open]]:border-b-[1px] [&[data-state=open]]:py-2 [&[data-state=open]]:border-white font-bold text-xl uppercase hover:no-underline">
                  {category.category}
                </AccordionTrigger>
                <AccordionContent className="flex flex-row flex-wrap gap-x-[20%] py-5 gap-y-4">
                  {category.subcategories.map((subcategory, keysub) => (
                    <Link className="uppercase" key={keysub} to={""}>
                      {subcategory.name}
                    </Link>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}
        <div className="mt-4">
        <Link className={"uppercase font-bold text-xl " + (window.location.href.includes('contactUs') ? 'text-[#0597FF]' : '')} to={"/contactUs"}>
          Contact us
        </Link>
        </div>
      </SheetContent>
    </Sheet>
  );
}
