import axios from "axios";
import { useState } from "react";
import Layout from "src/components/layout";
import { Button } from "src/components/ui/button";
import { ComboboxForm } from "src/components/ui/combobox";
import { Input } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import closeIcon from "../../assets/icons/close.svg";
import checkIcon from "../../assets/icons/check.svg";

export default function ContactUs() {
  const [formContact, setFormContact] = useState({
    nameUser: "",
    emailUser: "",
    messageUser: "",
    countryUser: "",
  });
  const [formContactError, setFormContactError] = useState<any>({});
  const [showToast, setShowToast] = useState<boolean>(false);

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
    },
  };

  const handleForm = (e: any) => {
    const { name, value } = e.target;
    setFormContact({
      ...formContact,
      [name]: value,
    });
  };

  const setCloseToast = () => {
    setShowToast(false);
  };

  const sendForm = async () => {
    console.log("1")
    const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(formContact);
    let errors = {};

    if (!formContact.nameUser || formContact.nameUser.trim().length === 0) {
      errors = { ...errors, nameError: "Campo Requerido" };
    }

    if (!validEmail.test(formContact.emailUser)) {
      errors = { ...errors, emailUserError: "Campo Inválido" };
    }

    if (
      !formContact.countryUser ||
      formContact.countryUser.trim().length === 0
    ) {
      errors = { ...errors, countryUser: "Campo Requerido" };
    }

    if (
      !formContact.messageUser ||
      formContact.messageUser.trim().length === 0
    ) {
      errors = { ...errors, messageError: "Campo Requerido" };
    }
    console.log(errors);
    setFormContactError(errors);
    if (Object.keys(errors).length === 0) {
      setShowToast(true);
      const data = {
        ...formContact,
        template_id: "d-e88f331e7d294a208eed5a0e8894c5e1",
      };
      setFormContact({
        nameUser: "",
        emailUser: "",
        messageUser: "",
        countryUser: "",
      });
      setFormContactError({});
      // await axios
      //   .post(
      //     "https://service-dev.barlleno.app/barllenoservice/functions/send/email/contactUs",
      //     { data },
      //     headers
      //   )
      //   .then(() => {});
    }
  };

  function myCallback(country: string) {
    setFormContact({
      ...formContact,
      countryUser: country,
    });
  }

  return (
    <Layout>
      <div className="bgHeroBanner">
        <div className="grid grid-cols-[2fr_5fr] md:grid-cols-[2fr_4fr] gap-4 px-3 max-w-[1400px] mx-auto py-[5%] items-center">
          <div className="mt-14 mx-auto max-w-[1400px] lg:flex lg:gap-9">
            <div className="[&>img]:mb-5 md:[&>img]:w-[150px]">
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/ElSalvador.png")}
                alt="El Salvador"
              />
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/Panama.png")}
                alt="Panama"
              />
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/Honduras.png")}
                alt="Honduras"
              />
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/Nicaragua.png")}
                alt="Nicaragua"
              />
            </div>
            <div className="[&>img]:mb-5 lg:mt-9 md:[&>img]:w-[150px]">
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/Belize.png")}
                alt="Belize"
              />
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/Guatemala.png")}
                alt="Guatemala"
              />
              <img
                className="h-auto w-[110px] lg:group-hover:w-[150px] lg:duration-1000"
                src={require("../../assets/countryFlags/CostaRica.png")}
                alt="Costa Rica"
              />
            </div>
          </div>
          <div className="text-white mt-14 lg:flex lg:gap-6">
            <h1 className="text-4xl font-bold max-w-min">Contact Us</h1>
            <div>
              <p className="mt-8 lg:mt-20 w-[80%]">
                Do you have a question? We're here to help. Send us a message
                and we'll be in contact
              </p>

              <div className="flex flex-col gap-3 justify-center mt-4 max-w-[800px]">
                <Input
                  value={formContact.nameUser}
                  onChange={handleForm}
                  type="text"
                  name="nameUser"
                  className="bg-[#515151] bg-opacity-80 border-none h-16 sm:w-[90%] min-w-[200px] placeholder:text-left placeholder:text-white"
                  placeholder="YOUR NAME"></Input>
                <p className="text-[#ff9696]">{formContactError.nameError}</p>
                <ComboboxForm callback={myCallback}></ComboboxForm>
                <p className="text-[#ff9696]">{formContactError.countryUser}</p>
                <Input
                  value={formContact.emailUser}
                  onChange={handleForm}
                  type="email"
                  name="emailUser"
                  className="bg-[#515151] bg-opacity-80 border-none h-16 sm:w-[90%] min-w-[200px] placeholder:text-left placeholder:text-white"
                  placeholder="EMAIL"></Input>
                <p className="text-[#ff9696]">
                  {formContactError.emailUserError}
                </p>
                <Textarea
                  value={formContact.messageUser}
                  onChange={handleForm}
                  name="messageUser"
                  className="bg-[#515151] bg-opacity-80 h-[120px] sm:h-[300px] border-none sm:w-[90%] min-w-[200px] placeholder:text-left placeholder:text-white"
                  placeholder="YOUR MESSAGE HERE"></Textarea>
                <p className="text-[#ff9696]">
                  {formContactError.messageError}
                </p>
                {!showToast ? (
                  <Button
                    onClick={sendForm}
                    className="h-16 sm:w-[90%] font-extrabold z-10">
                    SEND
                  </Button>
                ) : null}

                <div
                  className={
                    "flex relative w-[90%] -mt-24 text-black  overflow-hidden gap-5 items-center px-5 py-3 bg-[#B0FFDD] rounded-lg transition-all duration-500" +
                    (showToast ? " opacity-100 mt-0 z-10" : " -z-50 opacity-0")
                  }>
                  <img
                    alt="Check"
                    className="p-1 w-10 bg-[#d9d9d9] bg-opacity-10 rounded-full"
                    src={checkIcon}></img>
                  <div>
                    <span className="font-semibold">
                      TU MENSAJE HA SIDO ENVIADO
                    </span>
                    <p>
                      Uno de nuestros asesores te contactará a la brevedad
                      posible.
                    </p>
                  </div>
                  <img
                    alt="Close Icon"
                    onClick={setCloseToast}
                    className="w-5 hover:w-6 cursor-pointer transition-all duration-300"
                    src={closeIcon}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
